import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  Avatar,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';

const ContainerRow = styled.div`
  display: flex;
  align-items: baseline;
`;

const ContainerColumn = styled.div`
  flex: 1;
  flex-flow: column;
`;

const MemberTitle = styled.div`
  font-weight: bold;
`;

interface OwnProps {
  participantsCount;
  maxParticipantsCount;
  participants;
  onRemoveMember(memberId: string): void;
}

const MembersSection = ({ participantsCount, maxParticipantsCount, participants, onRemoveMember }: OwnProps) => {
  return (
    <ContainerColumn>
      <ContainerRow>
        <MemberTitle>Members list:</MemberTitle>
        {participantsCount}/{maxParticipantsCount}
      </ContainerRow>
      <List>
        {participants.map((participant) => (
          <>
            <ListItem button>
              <ListItemIcon>
                <ListItemAvatar>
                  <Avatar src={participant.avatar}></Avatar>
                </ListItemAvatar>
              </ListItemIcon>
              <ListItemText primary={participant.nickname} />
              <ListItemSecondaryAction>
                <Button edge="end" onClick={() => onRemoveMember(participant.id)}>
                  Delete from event
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        ))}
      </List>
    </ContainerColumn>
  );
};

export default MembersSection;
