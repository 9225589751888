import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from '@root/stores';

export class AdsStore {
  json = '';
  isVideoTypeAd = false;
  isLoading = false;
  videoUrl?: string;
  image?: File;
  errorMessage?: string;

  private root: RootStore;

  constructor(rootStore: RootStore) {
    this.root = rootStore;

    makeAutoObservable(this);
  }

  /**
   * uploads file to s3 bucket
   *
   * @param file
   * @returns media url or empty string if failure
   */
  async upload(file?: File): Promise<string> {
    this.errorMessage = undefined;
    try {
      if (file) {
        const fileUrl = await (await this.root.bucketApiService.uploadFile(file)).data;
        return Promise.resolve(fileUrl);
      }
    } catch (error) {
      // todo add logger here
      this.errorMessage = 'Could not upload';
    }
    return Promise.resolve('');
  }

  async submitCard(
    title: string,
    description: string,
    buttonText: string,
    siteUrl: string,
    playingTime: string
  ): Promise<void> {
    this.setLoading(true);

    const imgUrl = await this.upload(this.image);

    runInAction(() => {
      const ad = {
        title,
        description,
        buttonText,
        siteUrl,
        playingTime,
        imageUrl: imgUrl,
      };
      this.json = this.cleanObject(ad);
    });
    this.setLoading(false);
  }

  async submitVideo(buttonText: string, siteUrl: string, playingTime: string): Promise<void> {
    this.setLoading(true);

    runInAction(() => {
      const ad = {
        buttonText,
        siteUrl,
        playingTime,
        videoUrl: this.videoUrl,
      };
      this.json = this.cleanObject(ad);
    });
    this.setLoading(false);
  }

  async setVideoTypeAd(isVideoTypeAd: boolean): Promise<void> {
    this.isVideoTypeAd = isVideoTypeAd;
  }

  setImage(image: File) {
    this.image = image;
  }

  setVideoUrl(url: string) {
    this.videoUrl = url;
  }

  private setLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  private cleanObject(jsonObject: Object): string {
    const clone = JSON.parse(JSON.stringify(jsonObject));
    Object.entries(clone).forEach(([key, value]) => {
      if (value == null || value === '') {
        delete clone[key];
      }
    });
    return JSON.stringify(clone, null, 4);
  }
}
