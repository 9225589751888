import React, { useEffect, useState } from 'react';
import { Select, MenuItem, Grid, FormControl, FormHelperText } from '@material-ui/core';

interface Category {
    id: number;
    category_id: number;
    title: string;
    description: string;
    localizations_key: string;
    icon: string | null;
    subCategories?: Category[] | null;
}

interface CategorySelectorProps {
    categories: any[];
    onCategorySelect: (category: any) => void;
    error: string | number | undefined | null,
}
const CategorySelector: React.FC<CategorySelectorProps> = ({ categories, onCategorySelect, error }) => {
    const [selectedMainCategory, setSelectedMainCategory] = useState<Category | null>(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState<Category | null>(null);
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);

    const findCategoryById = (categories: Category[] | null | undefined, categoryId: number | undefined): Category | null => {
        return categories?.find(cat => cat.id === categoryId) || null;
    };
    useEffect(() => {
        setSelectedMainCategory(null);
        setSelectedSubCategory(null);
        setSelectedCategory(null);
        onCategorySelect(0); //clear category id if page or component reolad;
    }, [categories]);

    const handleMainCategoryChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        const categoryId = event.target.value as number;
        const category = findCategoryById(categories, categoryId);
        setSelectedMainCategory(category);
        setSelectedSubCategory(null);
        setSelectedCategory(null);
        if (category && category?.subCategories?.length) {
            return
        }
        onCategorySelect(category);
    };

    const handleSubCategoryChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        const categoryId = event.target.value as number;
        const category = findCategoryById(selectedMainCategory?.subCategories, categoryId);
        setSelectedSubCategory(category);
        setSelectedCategory(null);
        if (category && category?.subCategories?.length) {
            return
        }
        onCategorySelect(category);
    };

    const handleCategoryChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        const categoryId = event.target.value as number;
        const category = findCategoryById(selectedSubCategory?.subCategories, categoryId);
        setSelectedCategory(category);
        onCategorySelect(category);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <FormControl fullWidth error={Boolean(error) && !selectedMainCategory}>
                    <Select fullWidth value={selectedMainCategory ? selectedMainCategory.id : ""}
                        onChange={handleMainCategoryChange}>
                        {categories.map(category => (
                            <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>
                        ))}
                    </Select>
                    {error && !selectedMainCategory && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            </Grid>
            {selectedMainCategory && selectedMainCategory.subCategories && selectedMainCategory.subCategories.length > 0 && (
                <Grid item xs={4}>
                    <FormControl fullWidth error={Boolean(error) && !selectedSubCategory}>
                        <Select fullWidth key={selectedMainCategory.id}
                            value={selectedSubCategory ? selectedSubCategory.id : ""}
                            onChange={handleSubCategoryChange}>
                            {selectedMainCategory.subCategories.map(category => (
                                <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>
                            ))}
                        </Select>
                        {error && !selectedSubCategory && <FormHelperText>{error}</FormHelperText>}
                    </FormControl>
                </Grid>
            )}
            {selectedSubCategory && selectedSubCategory.subCategories && selectedSubCategory.subCategories.length > 0 && (
                <Grid item xs={4}>
                    <FormControl fullWidth error={Boolean(error) && !selectedCategory}>
                        <Select fullWidth key={selectedSubCategory.id} value={selectedCategory ? selectedCategory.id : ""}
                            onChange={handleCategoryChange}>
                            {selectedSubCategory.subCategories.map(category => (
                                <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>
                            ))}
                        </Select>
                        {error && !selectedCategory && <FormHelperText>{error}</FormHelperText>}
                    </FormControl>
                </Grid>
            )}
        </Grid>
    );
}

export default CategorySelector;
