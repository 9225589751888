import { LoadingView, useRootStore } from '@root/components';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import EventCard from '@root/pages/events/components/event-card';
import { mapDetailsToEvent } from '@root/types';
import styled from 'styled-components';
import { Button, Paper } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import MembersSection from './components/members-section';

const EventDetailsPage = observer((): JSX.Element => {
  const { eventsStore } = useRootStore();
  const { eventId } = useParams();

  useEffect(() => {
    eventsStore.fetchDetails(eventId);
  }, []);

  if (eventsStore.isLoading) {
    return <LoadingView title="Details are loading..." />;
  }

  const { eventDetails } = eventsStore;
  if (eventDetails) {
    const { id, description, participants, participantsCount, maxParticipantsCount } = eventDetails;
    return (
      <>
        <EventCard key={eventDetails.id} event={mapDetailsToEvent(eventDetails)} />
        <Wrapper>
          <DetailsContainer>
            <h2>Description</h2>
            <p>{description}</p>
            <MembersSection
              participantsCount={participantsCount}
              maxParticipantsCount={maxParticipantsCount}
              participants={participants}
              onRemoveMember={(memberId) => {
                eventsStore.deleteMember(eventId, memberId);
              }}
            />
          </DetailsContainer>
          <Link
            underline="none"
            component={RouterLink}
            to={{
              pathname: `${eventId}/edit`,
              query: { id: eventId },
            }}
          >
            <Button fullWidth>Edit</Button>
          </Link>
          <Button
            onClick={() => {
              eventsStore.delete(eventId);
            }}
          >
            Delete
          </Button>
        </Wrapper>
      </>
    );
  }
  return <h1>NA</h1>;
});

const DetailsContainer = styled(Paper)`
  padding: 15px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export default EventDetailsPage;
