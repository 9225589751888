import { BucketApiResponse } from '@root/types/bucket.types';
import { AxiosInstance } from 'axios';

import { ApiConfig, BASE_API_URL } from '@root/services/api/api-config';

export class BucketApiService {
  private readonly api: AxiosInstance;
  private readonly resource = {
    upload: 'storage/uploadFile',
    delete: 'storage/deleteFile',
  };

  constructor() {
    this.api = new ApiConfig({
      baseURL: BASE_API_URL,
    }).httpRequest;
  }

  async uploadFile(file: File): Promise<BucketApiResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return this.api.post(this.resource.upload, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async deleteFile(fileUrl: string): Promise<BucketApiResponse> {
    const params = {
      fileUrl,
    };
    return this.api.delete(this.resource.delete, { params });
  }
}
