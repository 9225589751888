export enum StatusCodes {
  OK = 200,
  Unauthorized = 401,
  NotFound = 404,
  ClientClosedRequest = 499,
  InternalServer = 500,
}

export interface JtResponse<T = any> {
  code: number;
  data: T;
  message: string;
  status: string;
}
