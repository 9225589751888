import {ProfileApiResponse} from '@root/types';
import {network} from '@root/services/api/network';

export class AuthService {
    private readonly resource = {
        sanctum: 'auth/csrf-cookie',
        login: 'auth/login?region=PL',
        logout: 'auth/logout',
        refreshToken: 'auth/refresh-token',
    };

    async login(phoneNumber: string, passcode: string): Promise<ProfileApiResponse> {
        const body = {
            phone_number: phoneNumber,
            pass_code: passcode,
        };

        return await network.get(this.resource.sanctum).then(async response => {
            return await network.post(this.resource.login, body);
        });
    }

    getCookie(key) {
        const b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
        return b ? b.pop() : "";
    }
}
