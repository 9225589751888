import { UserStorage } from '@root/services/storage/userStorage';
import { RootStore } from '@root/stores';
import { makeAutoObservable, runInAction } from 'mobx';

export class ProfileStore {
  isLoading = false;
  userId = '';

  private root: RootStore;
  private userStorage: UserStorage;

  constructor(rootStore: RootStore) {
    this.root = rootStore;
    this.userStorage = new UserStorage();
    makeAutoObservable(this);
  }

  async fetchProfile(): Promise<void> {
    this.setLoading(true);
    try {
      //const response = await this.root.profileApi.fetchProfile();
      runInAction(() => {
        //this.profile = response.data.data;
      });
    } finally {
      this.setLoading(false);
    }
  }

  private setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
}
