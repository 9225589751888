// import { Event, EventDetails, LocalizedDetails } from '@root/types';
import {FBevent, FBeventDetails, LocalizedDetails} from "@root/types";
import {makeAutoObservable, runInAction} from "mobx";

import {RootStore} from "@root/stores";

export class FBeventsStore {
    event?: FBevent;
    events: FBevent[] = [];
    eventDetails?: FBeventDetails;
    isLoading = false;

    picture?: File;

    private root: RootStore;

    constructor(rootStore: RootStore) {
        this.root = rootStore;

        makeAutoObservable(this);
    }

    setPicture(file: File) {
        this.picture = file;
    }

    async fetchEvents(): Promise<void> {
        this.setLoading(true);
        try {
            const response = await this.root.fbEventsAPI.fetchEvents();
            runInAction(() => {
                this.events = response.data.data;
            });
        } finally {
            this.setLoading(false);
        }
    }

    async fetchDetails(eventId: string): Promise<void> {
        this.setLoading(true);
        try {
            const response = await this.root.fbEventsAPI.fetchEventDetails(eventId);
            runInAction(() => {
                this.eventDetails = response.data.data;
            });
        } finally {
            this.setLoading(false);
        }
    }

    async delete(eventId: string): Promise<void> {
        this.setLoading(true);
        try {
            const response = await this.root.fbEventsAPI.delete(eventId);
            if (response.status === 200) {
                runInAction(() => {
                    this.events = this.events.filter((event) => event.id !== eventId);
                });
            } else {
                console.error(
                    `Failed to delete event with id: ${eventId}. Server responded with status: ${response.status}`
                );
            }
        } finally {
            this.setLoading(false);
        }
    }

    async bulkDelete(eventIds: string[]): Promise<void> {
        this.setLoading(true);
        try {
            const response = await this.root.fbEventsAPI.bulkDelete( eventIds);
            if (response.status === 200) {
                runInAction(() => {
                    this.events = this.events.filter((event) => !eventIds.includes(event.id));
                });
            } else {
                console.error(
                    `Failed to delete events with ids: ${eventIds.join(', ')}. Server responded with status: ${response.status}`
                );
            }
        } finally {
            this.setLoading(false);
        }
    }

    async createEventFromFBevent(event): Promise<string> {
        this.setLoading(true);
        const pictureFile = this.picture;
        let createdEventId: string;
        try {
            const response = await this.root.fbEventsAPI.createEventFromFBevent(event);
            createdEventId = response.data.id;
            if (pictureFile && createdEventId) {
                console.log('pictureFile', pictureFile);
                console.log('createdEventId', createdEventId)
                await this.root.eventsAPI.updatePhoto(createdEventId, pictureFile);
            }

            await this.fetchEvents();
        } finally {
            this.setLoading(false);
        }
        return createdEventId;
    }

    private setLoading(isLoading: boolean): void {
        this.isLoading = isLoading;
    }
}
