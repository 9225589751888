import type { AxiosResponse } from 'axios';
import { JtResponse } from '@root/types/api/api.types';

export type CategoriesApiResponse = AxiosResponse<JtResponse<Category[]>>;

export type CategoryDetailsApiResponse = AxiosResponse<JtResponse<CategoryDetailsData>>;

export type CreateCategoryApiResponse = AxiosResponse<JtResponse<string>>;

export type DeleteCategoryApiResponse = AxiosResponse<JtResponse<string>>;

export type EditCategoryApiResponse = AxiosResponse<JtResponse<string>>;

export enum COVERAGE_TYPE {
  KM = 'KM',
  COUNTRY = 'COUNTRY',
  ALL = 'ALL',
}

export interface Coverage {
  type: COVERAGE_TYPE;
  distance: number;
  countryName: string;
}

export interface Category {
  id: string;
  title: string;
  picture: string;
  icon: string;
  description: string;
  coverage: Coverage;
  parentId?: string;
  subCategories?: Category[];
}

export interface CategoryDetails {
  categoryId: string;
  icon: string;
  picture: string;
  details: LocalizedDetails[];
  subCategories?: CategoryDetails[];
}

export interface CategoryDetailsData {
  patentCategoryId: string;
  categoryDetails: CategoryDetails;
  iconUploadAvailable: boolean;
  pictureUploadAvailable: boolean;
}

export interface LocalizedDetails {
  localizations_key: string;
  title: string;
  description: string;
}

export interface CreateCategory {
  details: LocalizedDetails[];
  categoryCoverage: Coverage;
  existingId?: string;
  picture?: string;
  icon?: string;
  subCategories?: CreateCategory[];
}

export interface EditCategory {
  existingId: string;
  details: LocalizedDetails[];
}

export enum CATEGORY_TYPE {
  MAIN_CATEGORY = 'MAIN_CATEGORY',
  CATEGORY = 'CATEGORY',
  SUBCATEGORY = 'SUBCATEGORY',
}
