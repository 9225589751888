export interface Route {
  path: (...args: never[]) => string;
}

export interface RoutesConfig {
  root: Route;
  events: Route;
  event: Route;

  fbCollectors: Route;
  fbJobs: Route;
  fbEvents: Route;
  fbEvent: Route;

  editEvent: Route;
  createEvent: Route;
  createFromFbEvent: Route;

  users: Route;
  user: Route;
  categories: Route;
  category: Route;
  createCategory: Route;
  reportsSuggestions: Route;
  profile: Route;
  login: Route;
  ads: Route;
  policy: Route;
  notFound: Route;
  forbidden: Route;
}
