import {DeleteEventApiResponse, FBeventDetailsApiResponse, FBeventsApiResponse} from '@root/types';
import { network } from '@root/services/api/network';

export class FBeventsApiService {
    private readonly resource = {
        events: '/fb-events',
        editEvent: '/events',
        uploadPhoto: '/fb-events/upload-photo',
        removeMember: '/fb-events/delete-user',
    };

    async fetchEventDetails(eventId: string): Promise<FBeventDetailsApiResponse> {
        return network.get(`${this.resource.events}/${eventId}`);
    }
    async fetchEvents(): Promise<FBeventsApiResponse> {
        return network.get(this.resource.events);
    }


    async delete(eventId: string): Promise<DeleteEventApiResponse> {
        return network.delete(`${this.resource.events}/${eventId}`);
    }

    async bulkDelete(eventIds: string[]): Promise<DeleteEventApiResponse> {
        return network.delete(this.resource.events, { data: { ids: eventIds } });
    }

    async createEventFromFBevent(event): Promise<any> {
        const {
            address,
            categoryId,
            details,
            end,
            fbEventId,
            maxParticipantsCount,
            start,
            type
        } = event;
        const body = {
            address,
            categoryId,
            details,
            end,
            fbEventId,
            maxParticipantsCount,
            start,
            type
        };
        const response = await network.post(this.resource.editEvent, body);
        return response.data;
    }
}
