import {User} from '@root/types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    makeStyles,
} from '@material-ui/core'
import React from 'react'

interface UserCardProps {
    user: User;

    onDelete(userId: string): void;
}

function UserCard({user, onDelete}: UserCardProps) {
    const {nickName, gender, avatar, isRemovable} = user
    const [open, setOpen] = React.useState(false)

    const classes = useStyles()

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const deleteUser = (userId: string) => {
        onDelete(userId)
        handleClose()
    }

    return (
        <Card className={classes.card}>
            <CardContent>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div>
                        <div className={classes.top}>
                            <Avatar src={avatar}/>
                            <h4 style={{marginLeft: '10px'}}>{nickName}</h4>
                        </div>
                        <div>Gender: {gender}</div>
                    </div>
                    {isRemovable && (
                        <Button size="small" onClick={handleClickOpen}>
                            Delete
                        </Button>
                    )}
                </Grid>
            </CardContent>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">User will be removed
                        permanently.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => deleteUser(user.id)} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    )
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexFlow: 'column',
        margin: '5px',
    },
    top: {
        display: 'flex',
        flexFlow: 'row',
        margin: '5px',
    },
    card: {
        margin: '5px',
    },
})

export default React.memo(UserCard)
