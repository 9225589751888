import { Event, EventDetails, LocalizedDetails } from '@root/types';
import { makeAutoObservable, runInAction } from 'mobx';

import { RootStore } from '@root/stores';

export class EventsStore {
  event?: Event;
  events: Event[] = [];
  eventDetails?: EventDetails;
  isLoading = false;

  picture?: File;

  private root: RootStore;

  constructor(rootStore: RootStore) {
    this.root = rootStore;

    makeAutoObservable(this);
  }

  setPicture(file: File) {
    this.picture = file;
  }

  async fetchEvents(): Promise<void> {
    this.setLoading(true);
    try {
      const response = await this.root.eventsAPI.fetchEvents();
      runInAction(() => {
        this.events = response.data.data;
      });
    } finally {
      this.setLoading(false);
    }
  }

  async fetchDetails(id: string): Promise<void> {
    this.setLoading(true);
    try {
      const response = await this.root.eventsAPI.fetchEventDetails(id);
      runInAction(() => {
        this.eventDetails = response.data.data;
      });
    } finally {
      this.setLoading(false);
    }
  }

  async delete(eventId: string): Promise<void> {
    this.setLoading(true);
    try {
      const response = await this.root.eventsAPI.delete(eventId);
      await this.fetchEvents();
    } finally {
      this.setLoading(false);
    }
  }

  async deleteMember(eventId: string, memberId: string): Promise<void> {
    this.setLoading(true);
    try {
      await this.root.eventsAPI.deleteMember(eventId, memberId);
      await this.fetchDetails(eventId);
    } finally {
      this.setLoading(false);
    }
  }

  async updateEvent(event): Promise<void> {
    this.setLoading(true);
    const pictureFile = this.picture;
    try {
      await this.root.eventsAPI.updateEvent(event);
      if (pictureFile) {
        await this.root.eventsAPI.updatePhoto(event.id, pictureFile);
      }
      await this.fetchDetails(event.id);
    } finally {
      this.setLoading(false);
    }
  }
  async update(id: string, details: LocalizedDetails[]): Promise<void> {
    this.setLoading(true);

    try {
      const pictureFile = this.picture;
      if (pictureFile) {
        await this.root.eventsAPI.updatePhoto(id, pictureFile);
      }
    } catch (error) {
      // TODO log error into analytics
    }

    try {
      await this.root.eventsAPI.update(id, details);
      await this.fetchEvents();
    } finally {
      this.setLoading(false);
    }
  }

  private setLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }
}
