import React from 'react';
import { TableCell, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    collapsedContent: {
        width: 300,
        height: 30,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
});

interface CollapsibleTableCellProps {
    content: string;
    open: boolean;
}

const CollapsibleTableCell: React.FC<CollapsibleTableCellProps> = ({ content, open }) => {
    const classes = useStyles();

    return (
        <TableCell
            style={{
                verticalAlign: 'top',
                width: 300,
                height: 30,
                overflow: open ? 'visible' : 'hidden'
            }}
        >
            <div className={classes.collapsedContent}
                 style={{display: open ? 'none' : 'block'}}>
                {content}
            </div>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {content}
            </Collapse>
        </TableCell>
    );
};

export default CollapsibleTableCell;