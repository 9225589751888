import { LocalizedDetails, CATEGORY_TYPE, Coverage, CreateCategory } from '@root/types';
import { makeAutoObservable } from 'mobx';

import { RootStore } from '@root/stores';

export class CreateCategoryStore {
  isLoading = false;
  message = '';
  error = '';
  redirectToAllCategories = false;

  icon?: File;
  picture?: File;

  private root: RootStore;

  constructor(rootStore: RootStore) {
    this.root = rootStore;
    makeAutoObservable(this);
  }

  initState() {
    this.redirectToAllCategories = false;
  }

  async createCategory(details: LocalizedDetails[], coverage: Coverage, parentId?: string) {
    this.isLoading = true;
    this.redirectToAllCategories = false;

    let iconUrl = '';
    let pictureUrl = '';
    try {
      const iconFile = this.icon;
      if (iconFile) {
        iconUrl = await (await this.root.bucketApiService.uploadFile(iconFile)).data;
      }
      const pictureFile = this.picture;
      if (pictureFile) {
        const resp = await this.root.bucketApiService.uploadFile(pictureFile);
        pictureUrl = await resp.data;
      }
    } catch (error) {
      // TODO log error into analytics
    }

    const category: CreateCategory = {
      details,
      categoryCoverage: coverage,
      picture: pictureUrl,
      icon: iconUrl,
      existingId: parentId,
    };

    try {
      await this.root.categoriesApi.createNewCategory(CATEGORY_TYPE.CATEGORY, category);
      // TODO handle error response
      try {
        await this.root.categoriesApi.fetchCategories();
      } catch (e) {
        console.log(e);
      }
      this.redirectToAllCategories = true;
    } finally {
      this.isLoading = false;
    }
  }
}
