import { Event, Report, ReportUser } from '@root/types';
import { makeAutoObservable, runInAction } from 'mobx';

import { RootStore } from '@root/stores';

export class ReportsStore {
  reports: Report[] = [];
  reportedUsers: ReportUser[] = [];
  event?: Event;
  isLoading = false;

  private root: RootStore;

  constructor(rootStore: RootStore) {
    this.root = rootStore;

    makeAutoObservable(this);
  }

  async fetchAllReports(): Promise<void> {
    this.setLoading(true);

    await this.fetchEventReports();
    await this.fetchUserReports();

    this.setLoading(false);
  }

  async fetchEventReports(): Promise<void> {
    try {
      const response = await this.root.reportsApi.fetchReportedEvents();

      runInAction(() => {
        this.reports = response.data.data;
      });
    } catch (e) {
      console.log(e);
    }
  }

  async fetchUserReports(): Promise<void> {
    try {
      const response = await this.root.reportsApi.fetchReportedUsers();

      runInAction(() => {
        this.reportedUsers = response.data.data;
      });
    } catch (e) {
      console.log(e);
    }
  }

  async deteleReportOfReportedEvent(reportId: string): Promise<void> {
    this.setLoading(true);

    try {
      await this.root.reportsApi.deteleReportOfReportedEvent(reportId);
      await this.fetchAllReports();
    } catch (error) {
      // TODO handle failure
    } finally {
      this.setLoading(false);
    }
  }

  async deteleReportOfReportedUser(reportId: string): Promise<void> {
    this.setLoading(true);

    try {
      await this.root.reportsApi.deleteReportForReportedUser(reportId);
      await this.fetchAllReports();
    } catch (error) {
      // TODO handle failure
    } finally {
      this.setLoading(false);
    }
  }

  async deleteUser(userId: string): Promise<void> {
    this.setLoading(true);

    try {
      await this.root.reportsApi.deleteUser(userId);
      await this.fetchAllReports();
    } catch (error) {
      // TODO handle failure
    } finally {
      this.setLoading(false);
    }
  }

  async deleteEvent(eventId: string): Promise<void> {
    this.setLoading(true);

    try {
      await this.root.reportsApi.deleteEvent(eventId);
      await this.fetchAllReports();
    } catch (error) {
      // TODO handle failure
    } finally {
      this.setLoading(false);
    }
  }

  private setLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }
}
