import {
  CategoriesApiResponse,
  CategoryDetailsApiResponse,
  CATEGORY_TYPE,
  CreateCategory,
  EditCategory,
  CreateCategoryApiResponse,
  DeleteCategoryApiResponse,
  EditCategoryApiResponse,
} from '@root/types';
import { network } from '@root/services/api/network';
import { ApiConfig, BASE_API_URL } from '../api-config';

export class CategoriesApiService {
  private readonly resource = {
    categories: '/category/all',
    category: '/category',
    createDefaultCategory: '/category',
    createNewCategory: '/category/new',
    deleteCategory: '/category',
    edtiCategory: '/category/edit',
    uploadPictureFor: '/category/upload-picture-for',
    uploadIconFor: '/category/upload-icon-for',
  };

  async fetchCategories(lang: string = 'en'): Promise<CategoriesApiResponse> {
    return new ApiConfig({
      baseURL: BASE_API_URL,
    }).httpRequest.get(this.resource.categories, { headers: { 'Accept-Language': lang.toLowerCase() } });
  }

  async fetchCategoryDetails(id: string, lang: string = 'en'): Promise<CategoryDetailsApiResponse> {
    return network.get(`${this.resource.category}/${id}`, { headers: { 'Accept-Language': lang.toLowerCase() } });
  }

  async createCategory(category: CreateCategory): Promise<CreateCategoryApiResponse> {
    return network.post(this.resource.createDefaultCategory, category);
  }

  async createNewCategory(type: CATEGORY_TYPE, category: CreateCategory): Promise<CreateCategoryApiResponse> {
    return network.post(`${this.resource.createNewCategory}?type=${type}`, category);
  }

  async deleteCategory(id: string): Promise<DeleteCategoryApiResponse> {
    return network.delete(`${this.resource.deleteCategory}/${id}`);
  }
  async editCategory(category: EditCategory): Promise<EditCategoryApiResponse> {
    return network.post(this.resource.edtiCategory, category);
  }

  async uploadPictureFor(file: File, categoryId: string): Promise<string> {
    const formData = new FormData();
    formData.append('icon', file);
    formData.append('categoryId', categoryId);
    return network.post(this.resource.uploadPictureFor, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async uploadIconFor(file: File, categoryId: string): Promise<string> {
    const formData = new FormData();
    formData.append('icon', file);
    formData.append('categoryId', categoryId);
    return network.post(this.resource.uploadIconFor, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
