import React from 'react';
import { RootPage } from '@root/pages';
import { render } from 'react-dom';

import { RootProviders } from './components';

const locale = 'en-US'; // make dynamic in the future if needed

function loadLocaleData(locale: string) {
  switch (locale) {
    case 'en-US':
      return import('./compiled-translations/en.json');
    default:
      return import('./compiled-translations/en.json');
  }
}

async function bootstrapApplication() {
  const messages = await loadLocaleData(locale);

  render(
    <RootProviders locale={locale} messages={messages.default}>
      <RootPage />
    </RootProviders>,
    document.getElementById('app')
  );
}

bootstrapApplication();
