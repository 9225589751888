import type { AxiosResponse } from 'axios';
import { JtResponse, Event } from '@root/types';

export type ReportedApiResponse = AxiosResponse<JtResponse<Report[]>>;

export type ReportedUsersApiResponse = AxiosResponse<JtResponse<ReportUser[]>>;

export type DeleteReportApiResponse = AxiosResponse<JtResponse<string>>;

export interface Report {
  reportId: string;
  userId: string;
  reason: string;
  event: Event;
}

export interface CompromisedUser {
  id: string;
  nickName?: string;
  photoUrl?: string;
}

export interface ReportUser {
  id: string;
  reporterId: string;
  reportedUser: CompromisedUser;
  reason: string;
}
