import { UsersApiResponse } from '@root/types';

import { network } from '@root/services/api/network';

export class UsersApiService {
  private readonly resource = {
    users: 'users',
    searchUsers: 'users/search',
  };

  async fetchUsers(): Promise<UsersApiResponse> {
    return network.get(this.resource.users);
  }

  async delete(userId: string): Promise<string> {
    return network.delete(`${this.resource.users}/${userId}`);
  }

  async searchUsersByNickName(userNickName: string): Promise<UsersApiResponse> {
    return network.get(`${this.resource.searchUsers}?cursor=0&nickName=${userNickName}&size=10`);
  }
}
