import { LoadingView, ContainerWrapper, useRootStore } from '@root/components';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Category } from '@root/types';
import {
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

const SuggestedCategoriesPage = observer(() => {
  const { suggestCategoriesStore } = useRootStore();

  useEffect(() => {
    suggestCategoriesStore.fetchWaitingForReviewCategories();
  }, []);

  interface SuggestCategoryCardProps {
    category: Category;
    children?: React.ReactNode;
  }

  const SuggestCategoryCard = ({ category, children }: SuggestCategoryCardProps) => {
    const classes = useStyles();
    const { title, description, subCategories } = category;
    return (
      <Card key={category.id} className={classes.row}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className={classes.heading}>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.root}>
            {description && <Typography className={classes.description}>{description}</Typography>}
            {subCategories?.map((subCat) => (
              <SuggestCategoryCard key={subCat.id} category={subCat} children={children} />
            ))}
            {children}
          </AccordionDetails>
        </Accordion>
        <Button
          onClick={() => {
            suggestCategoriesStore.delete(category.id);
          }}
        >
          Delete
        </Button>
      </Card>
    );
  };

  if (suggestCategoriesStore.isLoading) {
    return <LoadingView title="Categories are loading..." />;
  }
  if (suggestCategoriesStore.categories.length === 0) {
    return <h3>Empty</h3>;
  }
  return (
    <ContainerWrapper>
      {suggestCategoriesStore.categories
        .filter((category) => !category.parentId)
        .map((category) => (
          <SuggestCategoryCard key={category.id} category={category} />
        ))}
    </ContainerWrapper>
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      flex: 1,
      flexFlow: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    description: {
      fontSize: theme.typography.pxToRem(10),
    },
    row: {
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
  })
);

export default SuggestedCategoriesPage;
