import React from 'react';
import ReportsPage from './reports/reports.page';
import SuggestedCategoriesPage from './category/suggested-categories.page';
import LoginPage from '@root/pages/login/login.page';
import { Divider } from '@material-ui/core';

const BodyPage = () => (
  <>
    <h2>Reports</h2>
    <ReportsPage />
    <Divider />
    <h2>Suggested categories</h2>
    <SuggestedCategoriesPage />
  </>
);

const ReportsAndSuggestionsPage = () => <>{localStorage.getItem('authToken') ? <BodyPage /> : <LoginPage />}</>;

export default ReportsAndSuggestionsPage;
