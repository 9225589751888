import {useCallback, useRef, useState} from 'react'
import Cron, {CronError} from 'react-js-cron-mui'
import {TextField, TextFieldProps} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    fullWith: {
        width: '100%',
    },
    cron: {
        '&.react-js-cron': {
            marginTop: '20px',
            display: 'flex',
            // justifyContent: 'space-between',
            alignItems: 'center',
        },
    },
    error: {
        marginTop: 20,
        color: 'red',
    }
});

interface CronInputProps {
    value: string;
    onChange: (newValue: string) => void;
}

const CronInput: React.FC<CronInputProps> = ({value, onChange}) => {
    const inputRef = useRef<TextFieldProps>()
    const [textValue, setTextValue] = useState(value);
    const [error, onError] = useState<CronError>()
    const classes = useStyles();
    const customSetValue = useCallback(
        (newValue: string) => {
            setTextValue(newValue);
            onChange(newValue);
        },
        [onChange]
    )

    return (
        <div>
            <Cron className={classes.cron} value={value} setValue={customSetValue} onError={onError}/>
            <TextField className={classes.fullWith}
                       value={textValue}
                       inputRef={inputRef}
                       onBlur={(event) => {
                           customSetValue(event.target.value)
                       }}
                       onChange={(event: any) => {
                           customSetValue(event.target.value)
                       }}
            />
            {error && (
                <p className={classes.error}>
                    {error.description}
                </p>
            )}
        </div>
    )
}
export default CronInput