import React from "react";
import {routesConfig} from "@root/services";
import clsx from "clsx";
import {
    makeStyles,
    // useTheme,
    Theme,
    createStyles,
    createTheme,
    ThemeProvider,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import PolicyIcon from "@material-ui/icons/Policy";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleOutline from "@material-ui/icons/PeopleOutline";
import Event from "@material-ui/icons/Event";
import ListIcon from "@material-ui/icons/List";
import Category from "@material-ui/icons/Category";
import OutlinedFlag from "@material-ui/icons/OutlinedFlagOutlined";
import ShoppingBasketOutlined from "@material-ui/icons/ShoppingBasketOutlined";

import {
    Route, Link,
    // Redirect
} from "react-router-dom";
import HomePage from "./home/home.page";
import UsersPage from "./users/users.page";
import CategoriesPage from "./category/categories.page";
import EventsPage from "./events/events.page";
import EventDetailsPage from "./events/event-details.page";
import ReportsAndSuggestionsPage from "./reports.suggestions.page";
import CreateCategoryPage from "./category/create-category/create.category.page";
import CategoryDetailsPage from "./category/category-details.page";
import EditEventPage from "./events/edit-event.page";
import AdsPage from "./ads/ads.page";
import PolicyPage from "./policy/policy.page";
import ProfilePage from "@root/pages/profile/profile.page";
import LoginPage from "@root/pages/login/login.page";
import {appConfig} from "@root/boot/app-config";

import FBeventsCollectorsPage from "@root/pages/fb-events/fb-events-collectors.page";
import FBjobsTablePage from "@root/pages/fb-events/fb-jobs-table.page";
import FBeventsTablePage from "@root/pages/fb-events/fb-events.page";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const drawerWidth = 240;

const theme = createTheme({
    palette: {
        type: "light",
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        appBar: {
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: "none",
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: "flex-end",
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
    })
);

const MENU_ITEMS = [
    {
        title: "Events",
        icon: <Event/>,
        path: routesConfig.events.path(),
    },

    {
        title: "FB event",
        icon: <ListIcon/>,
        items: [
            {
                title: "FB Collectors",
                path: routesConfig.fbCollectors.path(),
            },
            {
                title: "FB Jobs",
                path: routesConfig.fbJobs.path(),
            },
            {
                title: "FB Events",
                path: routesConfig.fbEvents.path(),
            },
        ],
    },
    {
        title: "Users",
        icon: <PeopleOutline/>,
        path: routesConfig.users.path(),
    },
    {
        title: "Categories",
        icon: <Category/>,
        path: routesConfig.categories.path(),
    },
    {
        title: "Reports/suggestions",
        icon: <OutlinedFlag/>,
        path: routesConfig.reportsSuggestions.path(),
    },
    {
        title: "Ads",
        icon: <ShoppingBasketOutlined/>,
        path: routesConfig.ads.path(),
    },
    {
        title: "Policy",
        icon: <PolicyIcon/>,
        path: routesConfig.policy.path(),
    },
    // {
    //   title: 'Settings',
    //   icon: <Settings />,
    //   path: routesConfig.categories.path(),
    // },
];

function NavDrawer() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <AppBar
                    position="fixed"
                    color="inherit"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, open && classes.hide)}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6" noWrap>
                            JoitIn Admin panel
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === "ltr" ? (
                                <ChevronLeftIcon/>
                            ) : (
                                <ChevronRightIcon/>
                            )}
                        </IconButton>
                    </div>
                    <Divider/>
                    <List>
                        <ListItem
                            button
                            key="Account"
                            component={Link}
                            to={routesConfig.profile.path()}
                        >
                            <ListItemIcon>
                                <AccountCircle/>
                            </ListItemIcon>
                            <ListItemText primary="Account"/>
                        </ListItem>
                    </List>
                    <Divider/>

                    <List>
                        {MENU_ITEMS.map((item) =>
                            item.items ? (
                                <Accordion key={item.title}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <Typography>{item.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List>
                                            {item.items.map((subItem) => (
                                                <ListItem
                                                    button
                                                    key={subItem.title}
                                                    component={Link}
                                                    to={subItem.path}
                                                >
                                                    <ListItemText primary={subItem.title}/>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            ) : (
                                <ListItem
                                    button
                                    key={item.title}
                                    component={Link}
                                    to={item.path}
                                >
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.title}/>
                                </ListItem>
                            )
                        )}
                    </List>

                    <ListItem>
                        <ListItemText
                            primary={`Environment: ${appConfig.environment_name}`}
                        />
                        <ListItemText primary={`v: ${appConfig.version}`}/>
                    </ListItem>
                </Drawer>
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: open,
                    })}
                >
                    <div className={classes.drawerHeader}/>

                    <Route
                        exact
                        path={routesConfig.fbCollectors.path()}
                        component={FBeventsCollectorsPage}
                    />
                    <Route
                        exact
                        path={routesConfig.fbJobs.path()}
                        component={FBjobsTablePage}
                    />
                    <Route
                        exact
                        path={routesConfig.fbEvents.path()}
                        component={FBeventsTablePage}
                    />

                    <Route exact path={routesConfig.root.path()} component={HomePage}/>

                    <Route exact path={routesConfig.users.path()} component={UsersPage}/>

                    <Route
                        exact
                        path={routesConfig.categories.path()}
                        component={CategoriesPage}
                    />

                    <Route
                        exact
                        path={routesConfig.category.path()}
                        component={CategoryDetailsPage}
                    />

                    <Route
                        exact
                        path={routesConfig.createCategory.path()}
                        component={CreateCategoryPage}
                    />

                    <Route
                        exact
                        path={routesConfig.events.path()}
                        component={EventsPage}
                    />

                    <Route
                        exact
                        path={routesConfig.event.path()}
                        component={EventDetailsPage}
                    />

                    <Route
                        exact
                        path={routesConfig.editEvent.path()}
                        component={EditEventPage}
                    />
                    <Route
                        exact
                        path={routesConfig.createEvent.path()}
                        component={EditEventPage}
                    />
                    <Route
                        exact
                        path={routesConfig.createFromFbEvent.path()}
                        component={EditEventPage}
                    />


                    <Route
                        exact
                        path={routesConfig.reportsSuggestions.path()}
                        component={ReportsAndSuggestionsPage}
                    />

                    <Route exact path={routesConfig.ads.path()} component={AdsPage}/>

                    <Route
                        exact
                        path={routesConfig.policy.path()}
                        component={PolicyPage}
                    />

                    <Route
                        exact
                        path={routesConfig.profile.path()}
                        component={ProfilePage}
                    />

                    <Route exact path={routesConfig.login.path()} component={LoginPage}/>

                    <Route
                        exact
                        path={routesConfig.forbidden.path()}
                        render={() => <h2>Forbidden</h2>}
                    />

                    <Route
                        exact
                        path={routesConfig.notFound.path()}
                        render={() => <h2>Not found</h2>}
                    />
                </main>
            </ThemeProvider>
        </div>
    );
}

function MainNav() {
    return <NavDrawer/>;
}

export default React.memo(MainNav);
