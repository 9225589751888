import { JtResponse } from '@root/types';
import { AxiosInstance, AxiosResponse } from 'axios';
import { ApiConfig, BASE_API_URL } from './api-config';

export interface Policy {
  policy?: string;
}

export type PolicyApiResponse = AxiosResponse<JtResponse<Policy>>;

export class PolicyService {
  private readonly api: AxiosInstance;

  private readonly resource = {
    policy: 'faq/policy',
    update: 'faq/policy',
  };

  constructor() {
    this.api = new ApiConfig({
      baseURL: BASE_API_URL,
    }).httpRequest;
  }

  async policy(): Promise<PolicyApiResponse> {
    return this.api.get(this.resource.policy);
  }

  async updatePolicy(policy: string): Promise<PolicyApiResponse> {
    const body = {
      policy,
    };
    return this.api.put(this.resource.update, body);
  }
}
