import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { LoadingView, useRootStore } from '@root/components';
import { makeStyles } from '@material-ui/core/styles';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import LoginPage from '@root/pages/login/login.page';
import CollapsibleTableCell from '@root/pages/fb-events/components/collapsible-table-cell';


import DeleteConfirmation from "@root/pages/fb-events/components/delete-confirmation";
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';


const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%'
    },
    container: {

        minHeight: '100%'
    },
    actionCell: {
        padding: 0,
        verticalAlign: 'top',
        whiteSpace: 'nowrap',
    },
    noWrapCell: {
        verticalAlign: 'top',
        whiteSpace: 'nowrap',
        maxWidth: 200,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    jobNameCell: {
        minWidth: 150,
    },
});

const FBeventsPage = observer(() => {
    const classes = useStyles();
    const { fbEventsStore } = useRootStore();
    const [open, setOpen] = React.useState<Record<string, boolean>>({});
    useEffect(() => {
        fbEventsStore.fetchEvents();
    }, []);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [sortedField, setSortedField] = useState<string>('job_name');

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [eventToDelete, setEventToDelete] = useState<string | null>(null);

    const handleDelete = (id: string) => {
        setEventToDelete(id);
        setDeleteDialogOpen(true);
    };

    const confirmDelete = () => {
        fbEventsStore.delete(eventToDelete!)
            .then(() => {
                console.log(`Delete event with id: ${eventToDelete}`);
                setDeleteDialogOpen(false);
            })
            .catch((error) => {
                console.error(`Failed to delete event with id: ${eventToDelete}. Error: ${error}`);
            });
    };

    const cancelDelete = () => {
        setDeleteDialogOpen(false);
    };

    const handleSortRequest = (property: string) => {
        const isAsc = sortedField === property && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortedField(property);
    };

    const sortData = (data: any[], field: string, direction: 'asc' | 'desc') => {
        return data.slice().sort((a, b) => {
            if (a[field] < b[field]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[field] > b[field]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    };

    const sortedEvents = sortData(fbEventsStore.events, sortedField, sortDirection);


    if (fbEventsStore.isLoading) {
        return <LoadingView title="Events are loading..." />;
    }

    if (!localStorage.getItem('authToken')) {
        return <LoginPage />;
    }

    return (<>
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Actions</TableCell>
                            <TableCell style={{ width: 50 }}>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortedField === 'job_name'}
                                    direction={sortDirection}
                                    onClick={() => handleSortRequest('job_name')}
                                >
                                    FB job name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Creator</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Start Timestamp</TableCell>
                            <TableCell>End Timestamp</TableCell>
                        </TableRow>
                    </TableHead>

                    {
                        (fbEventsStore.events.length === 0) ?
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={8}>
                                        <b>No events found.</b>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {sortedEvents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((event) => (
                                    <TableRow
                                        key={event.id}
                                        onClick={() => setOpen(prevOpen => ({
                                            ...prevOpen,
                                            [event.id]: !prevOpen[event.id]
                                        }))}>
                                        <TableCell className={classes.actionCell}>
                                            <Link component={RouterLink}
                                                to={`/events/${event.id}/create-from-fb-event`}>
                                                <IconButton>
                                                    <EditIcon />
                                                </IconButton>
                                            </Link>
                                            <IconButton onClick={(e) => {
                                                e.stopPropagation();
                                                handleDelete(event.id);
                                            }}>
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell className={classes.noWrapCell}>{event.name}</TableCell>
                                        <CollapsibleTableCell content={event.description} open={open[event.id]} />
                                        <TableCell className={classes.jobNameCell}>{event.job_name ? event.job_name : '---'}</TableCell>
                                        <CollapsibleTableCell content={event.creator} open={open[event.id]} />
                                        <TableCell className={classes.noWrapCell}>{event.country}</TableCell>
                                        <CollapsibleTableCell content={event.address} open={open[event.id]} />
                                        <TableCell
                                            className={classes.noWrapCell}>{event.start_timestamp}</TableCell>
                                        <TableCell className={classes.noWrapCell}>{event.end_timestamp}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                    }
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={fbEventsStore.events.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>

        <DeleteConfirmation
            open={deleteDialogOpen}
            onClose={cancelDelete}
            onConfirm={confirmDelete}
        />
    </>
    );
});

export default FBeventsPage;