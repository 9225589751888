import { BucketApiService } from "@root/services/api/bucket-api.service";
import { CategoriesApiService } from "@root/services/api/categories-api";
import { EventsApiService } from "@root/services/api/events-api";
import { FBeventsApiService } from "@root/services/api/fb-events-api";
import { FBcollectorsApiService } from "@root/services/api/fb-events-api";
import { FBjobsApiService } from "@root/services/api/fb-events-api";


import { ReportsApiService } from "@root/services/api/reports-api";
import { SuggestionsApiService } from "@root/services/api/suggestions-api";
import { UsersApiService } from "@root/services/api/users-api";
import { makeAutoObservable } from "mobx";
import { CategoriesStore } from "../categories";
import { SuggestCategoriesStore } from "../categories/suggest.categories.store";
import { EventsStore } from "../events";
import { FBeventsStore } from "../fb-events";
import { FBcollectorsStore } from "@root/stores/fb-events";
import { FBjobsStore } from "@root/stores/fb-events/fb-jobs.store";


import { ReportsStore } from "../reports/reports.store";
import { UsersStore } from "../users";
import { AdsStore } from "../ads";
import { BucketStore } from "../bucket/bucket.store";
import { ProfileStore } from "@root/stores/profile/profile.store";
import { CreateCategoryStore } from "../categories/create.category.store";
import { CategoryDetailsStore } from "../categories/category.details.store";
import { AuthService } from "@root/services/api/auth-api/auth-api.servise";
import { AuthStore } from "../auth.store";
import { UserStorage } from "@root/services/storage/userStorage";

export class RootStore {
  authStore: AuthStore;
  usersStore: UsersStore;
  usersApi: UsersApiService;
  eventsAPI: EventsApiService;
  eventsStore: EventsStore;

  fbEventsAPI: FBeventsApiService;
  fbEventsStore: FBeventsStore;
  fbCollectorsStore: FBcollectorsStore;
  fbCollectorsApi: FBcollectorsApiService;

  fbJobsStore: FBjobsStore;
  fbJobsApi: FBjobsApiService;

  categoriesStore: CategoriesStore;
  categoryDetailsStore: CategoryDetailsStore;
  createCategoryStore: CreateCategoryStore;
  categoriesApi: CategoriesApiService;
  suggestionsApiService: SuggestionsApiService;
  suggestCategoriesStore: SuggestCategoriesStore;
  reportsApi: ReportsApiService;
  reportsStore: ReportsStore;
  profileStore: ProfileStore;
  authService: AuthService;
  adsStore: AdsStore;
  bucketApiService: BucketApiService;
  bucketStore: BucketStore;

  constructor() {
    makeAutoObservable(this);
    this.usersStore = new UsersStore(this);
    this.usersApi = new UsersApiService();
    this.eventsAPI = new EventsApiService();
    this.eventsStore = new EventsStore(this);

    this.fbEventsAPI = new FBeventsApiService();
    this.fbEventsStore = new FBeventsStore(this);
    this.fbCollectorsApi = new FBcollectorsApiService();
    this.fbCollectorsStore = new FBcollectorsStore(this);

    this.fbJobsApi = new FBjobsApiService();
    this.fbJobsStore = new FBjobsStore(this);

    this.categoriesApi = new CategoriesApiService();
    this.categoriesStore = new CategoriesStore(this);
    this.categoryDetailsStore = new CategoryDetailsStore(this);
    this.createCategoryStore = new CreateCategoryStore(this);
    this.suggestionsApiService = new SuggestionsApiService();
    this.suggestCategoriesStore = new SuggestCategoriesStore(this);
    this.reportsApi = new ReportsApiService();
    this.reportsStore = new ReportsStore(this);
    this.profileStore = new ProfileStore(this);
    this.authService = new AuthService();
    this.adsStore = new AdsStore(this);
    this.bucketApiService = new BucketApiService();
    this.bucketStore = new BucketStore(this);
    this.authStore = new AuthStore(this.authService, new UserStorage());
  }
}
