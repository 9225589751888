import {RoutesConfig} from "./routes.config";

const EVENT_ID = ":eventId";
const USER_ID = ":userId";
const CATEGORY_ID = ":categoryId";
const FB_EVENT_ID = ":fbEventId";

export const routesConfig: RoutesConfig = {
    root: {
        path: () => "/",
    },

    events: {
        path: () => "/events",
    },

    event: {
        path: (eventId: string = EVENT_ID) =>
            `${routesConfig.events.path()}/${eventId}`,
    },

    editEvent: {
        path: (eventId: string = EVENT_ID) =>
            `${routesConfig.events.path()}/${eventId}/edit`,
    },

    createEvent: {
        path: () => `${routesConfig.events.path()}/create`,
    },

    createFromFbEvent: {
        path: (fbEventId: string = FB_EVENT_ID) => `${routesConfig.events.path()}/${fbEventId}/create-from-fb-event`,
    },


    fbEvents: {
        path: () => "/fb-events",
    },
    fbEvent: {
        path: (eventId: string = EVENT_ID) =>
            `${routesConfig.fbEvents.path()}/${eventId}`,
    },

    fbCollectors: {
        path: () => "/fb-collectors",
    },

    fbJobs: {
        path: () => "/fb-jobs",
    },

    users: {
        path: () => "/users",
    },

    user: {
        path: (userId: string = USER_ID) =>
            `${routesConfig.users.path()}/${userId}`,
    },

    categories: {
        path: () => "/categories",
    },

    category: {
        path: (categoryId: string = CATEGORY_ID) =>
            `${routesConfig.categories.path()}/${categoryId}`,
    },

    createCategory: {
        path: () => "/categories/create",
    },

    reportsSuggestions: {
        path: () => "/reports-suggestions",
    },

    login: {
        path: () => "/login",
    },

    profile: {
        path: () => "/profile",
    },

    ads: {
        path: () => "/ads",
    },

    policy: {
        path: () => "/policy",
    },

    notFound: {
        path: () => "/not-found",
    },

    forbidden: {
        path: () => "/forbidden",
    },
};
