import { CategoryDetails } from '@root/types';
import { Button, Card, Link, Paper } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

interface CategoryCardProps {
  category: CategoryDetails;
  onDelete(id: string): void;
}

const ShortCategoryCard = ({ category, onDelete }: CategoryCardProps) => {
  const { details } = category;
  return (
    <>
      <Paper style={{ display: 'flex', marginBottom: '10px', justifyContent: 'space-between' }}>
        {details.map((item) => (
          <Card>
            <h3>{item.localizations_key}</h3>
            <h2>{item.title}</h2>
          </Card>
        ))}
        <div>
          <Link
            underline="none"
            component={RouterLink}
            to={{
              pathname: `${category.categoryId}`,
              query: { categoryId: category.categoryId },
            }}
          >
            Details
          </Link>
          <Button
            onClick={() => {
              onDelete(category.categoryId);
            }}
          >
            Delete
          </Button>
        </div>
      </Paper>
    </>
  );
};

export default React.memo(ShortCategoryCard);
