import { Category } from '@root/types';
import { makeAutoObservable, runInAction } from 'mobx';

import { CategoriesStore, RootStore } from '@root/stores';

export class SuggestCategoriesStore {
  categories: Category[] = [];
  category?: Category;
  isLoading = false;

  private root: RootStore;

  constructor(rootStore: RootStore) {
    this.root = rootStore;

    makeAutoObservable(this);
  }

  async fetchWaitingForReviewCategories(): Promise<void> {
    this.isLoading = true;

    try {
      const response = await this.root.suggestionsApiService.fetchWaitingForReviewCategories();

      this.categories = response.data.data;
    } finally {
      this.isLoading = false;
    }
  }

  async delete(id: string): Promise<void> {
    this.isLoading = true;

    try {
      await this.root.categoriesApi.deleteCategory(id);
      await this.fetchWaitingForReviewCategories();
    } finally {
      this.isLoading = false;
    }
  }
}
