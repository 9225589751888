import { User } from '@root/types/users';
import { makeAutoObservable, runInAction } from 'mobx';

import { RootStore } from '@root/stores';

export class UsersStore {
  users: User[] = [];
  filteringUsers: User[] = [];
  areUsersLoading = false;

  private root: RootStore;

  constructor(rootStore: RootStore) {
    this.root = rootStore;

    makeAutoObservable(this);
  }

  async fetchUsers(): Promise<void> {
    this.setUsersLoading(true);

    try {
      const response = await this.root.usersApi.fetchUsers();

      runInAction(() => {
        this.users = response.data.data;
        this.filteringUsers = this.users;
      });
    } finally {
      this.setUsersLoading(false);
    }
  }

  async deleteUser(userId: string): Promise<void> {
    this.setUsersLoading(true);
    try {
      await this.root.usersApi.delete(userId);
      this.fetchUsers();
    } finally {
      this.setUsersLoading(false);
    }
  }

  async localSearch(searchPhrase: string): Promise<void> {
    if (searchPhrase === '') {
      this.users = this.filteringUsers;
    }
    this.users = this.filteringUsers.filter((user) => user.nickName.toLowerCase().includes(searchPhrase.toLowerCase()));
  }

  async searchUsersByNickName(nickName: string): Promise<void> {
    this.setUsersLoading(true);
    try {
      const response = await this.root.usersApi.searchUsersByNickName(nickName);
      runInAction(() => {
        this.users = response.data.data;
      });
    } finally {
      this.setUsersLoading(false);
    }
  }

  private setUsersLoading(areLoading: boolean): void {
    this.areUsersLoading = areLoading;
  }
}
