import { LoadingView, useRootStore } from '@root/components';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import styled from 'styled-components';
import { Button, Paper } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import ImageUploadView from '@root/components/image-upload/imageUpload.view';
import LocalizedDetailsForm from './components/localizedDetails.form';
import { LocalizedDetails, COVERAGE_TYPE } from '@root/types';
import CoverageForm from './components/coverage.form';
import { routesConfig } from '@root/services';

interface FormValues {
  subCategories?: [];
}

const initialValues: FormValues = {};

const validationSchema = Yup.object().shape({
  subCategories: Yup.array().of(
      Yup.object().shape({
        localizations_key: Yup.string().min(2).required('Locale is required.'),
        title: Yup.string().min(1).max(256).required('Title is required.'),
        description: Yup.string().min(4).max(1000).required('Description is required.'),
      })
  ),
});

const CreateCategoryPage = observer(() => {
  const [details, setDetails] = React.useState({});
  const [coverage, setCoverage] = React.useState({
    type: COVERAGE_TYPE.ALL,
    distance: 0,
    countryName: '',
  });

  const location = useLocation();
  const parentCategoryId = location?.state?.parentCategoryId;

  const { createCategoryStore, categoryDetailsStore } = useRootStore();
  const history = useHistory();

  useEffect(() => {
    categoryDetailsStore.fetchParentCategoryDetails(parentCategoryId);
  }, []);

  if (createCategoryStore.isLoading || categoryDetailsStore.isLoading) {
    return <LoadingView title="Loading..." />;
  }

  if (createCategoryStore.redirectToAllCategories) {
    history.push({ pathname: routesConfig.categories.path() });
  }

  if (createCategoryStore.message) {
    return (
        <>
          <h1>{createCategoryStore.message}</h1>
          <Button
              onClick={() => {
                history.goBack();
              }}
          >
            Ok
          </Button>
        </>
    );
  }

  const onIcon = (file: File) => {
    createCategoryStore.icon = file;
  };

  const onPicture = (file: File) => {
    createCategoryStore.picture = file;
  };

  const onSubmit = () => {
    createCategoryStore.createCategory(details as LocalizedDetails[], coverage, parentCategoryId);
  };

  const parentCategoryDetails = categoryDetailsStore.parentCategoryName();

  const ParentCategoryCard = () => (
      <Paper style={{ padding: '20px' }}>
        {parentCategoryId && parentCategoryDetails && parentCategoryDetails?.details && <h1> Parent category:</h1>}
        {parentCategoryId &&
            parentCategoryDetails &&
            parentCategoryDetails?.details &&
            parentCategoryDetails?.details?.map((details) => (
                <>
                  <h2>Title:</h2>
                  <h3> {details.title}</h3>
                </>
            ))}
      </Paper>
  );

  return (
      <FormWrapper>
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur
            onSubmit={onSubmit}
        >
          {({
              values,
              touched,
              errors,
              setTouched,
              handleSubmit,
              handleChange,
              handleBlur,
              isValid,
              setValues,
            }: FormikProps<FormValues>) => (
              <>
                <ParentCategoryCard />
                <h1> New category:</h1>
                <LocalizedDetailsForm
                    editableLocalization
                    onChange={(newDetails) => {
                      setDetails(newDetails);
                    }}
                />
                <TextInputWrapper>
                  <ImageUploadView buttonText="Choose icon" onImage={(file) => onIcon(file)} />
                  <ImageUploadView buttonText="Choose picture" onImage={(file) => onPicture(file)} />
                </TextInputWrapper>
                <CoverageForm
                    coverage={coverage}
                    onChange={(newCoverage) => {
                      setCoverage(newCoverage);
                    }}
                />
                <SaveButtonWrapper>
                  <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      disabled={!isValid}
                      onClick={() => {
                        handleSubmit();
                      }}
                  >
                    Save
                  </Button>
                </SaveButtonWrapper>
              </>
          )}
        </Formik>
      </FormWrapper>
  );
});

const FormWrapper = styled.div`
  flex-direction: column;
  padding-horizontal: 20;
`;

const TextInputWrapper = styled.div`
  margin-vertical: 20;
`;

const SaveButtonWrapper = styled.div`
  margin-top: '20px';
  margin-bottom: '20px';
`;

export default CreateCategoryPage;