import {LoadingView, useRootStore} from '@root/components';
import {observer} from 'mobx-react-lite';

import {useHistory, useParams} from 'react-router';
import styled from 'styled-components';
import {Button, Paper} from '@material-ui/core';


import EventFormFields from './components/event-inputs';

import {useFetchAndTransformEvent} from '@root/hooks/useFetchAndTransformEvent';
import {EventTypeEnum} from '@root/models/EventTypeEnum';
import {useState} from "react";
import {useFormik} from 'formik';

const EditEventPage = observer((): JSX.Element => {
    const {eventsStore, fbEventsStore} = useRootStore();
    const history = useHistory();
    const {eventId, fbEventId} = useParams<{ eventId: string, fbEventId: string }>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const {
        editedEvent,
        setEditedEvent,
        eventType,
    } = useFetchAndTransformEvent(eventId, fbEventId, eventsStore, fbEventsStore);
    const handleEventChange = (updatedEvent) => {
        setEditedEvent(updatedEvent);
    };

    interface Values {
        details: { locale: string, title: string, description: string }[],
        start: string | undefined;
        end: string | undefined;
        address: { address: string } | undefined;
        categoryId: number | string | null,
        maxParticipantsCount: number | string,
    }

    const formik = useFormik<Values>({
        initialValues: {
            details: editedEvent?.details?.map(detail => ({
                locale: detail.locale,
                title: detail.title || '',
                description: detail.description || '',
            })) || [],
            start: editedEvent?.start || '',
            end: editedEvent?.end || '',
            address: editedEvent?.address?.address || '',
            categoryId: editedEvent?.categoryId || 0,
            maxParticipantsCount: editedEvent?.maxParticipantsCount || 0,
        },
        validate: (values) => {
            let errors: Partial<Values> = {};

            if (!values.details || values.details.length === 0) {
                if (!errors.details || !Array.isArray(errors.details)) {
                    errors.details = [];
                }
                errors.details[0] = {title: 'Required'};
            } else {
                values.details.forEach((detail, index) => {
                    if (!detail.title || detail.title.length < 5) {
                        if (!errors.details || !Array.isArray(errors.details)) {
                            errors.details = [];
                        }
                        errors.details[index] = {
                            ...errors.details[index],
                            title: 'Required and should be at least 5 characters long',
                        };
                    }
                    if (!detail.description || detail.description.length < 5) {
                        if (!errors.details || !Array.isArray(errors.details)) {
                            errors.details = [];
                        }
                        errors.details[index] = {
                            ...errors.details[index],
                            description: 'Required and should be at least 5 characters long',
                        };
                    }
                    if (detail.description && detail.description.length > 1000) {
                        if (!errors.details || !Array.isArray(errors.details)) {
                            errors.details = [];
                        }
                        errors.details[index] = {
                            ...errors.details[index],
                            description: 'The field should not exceed 1000 characters.',
                        };
                    }
                });
            }
            if (!values.start) {
                errors.start = 'Required';
            }
            if (!values.end) {
                errors.end = 'Required';
            }
            if (!values.maxParticipantsCount) { 
                errors.maxParticipantsCount = 'Please enter a number from 1-9999 people';
            }
            if (values.start && values.end) {
                const start = new Date(values.start);
                const end = new Date(values.end);
                if (end <= start) {
                    errors.end = 'End date must be later than start date';
                }
            }
            if (!values.address || !values.address.address) {
                errors.address = {address: 'Required'};
            }

            if (!values.categoryId) {
                errors.categoryId = 'Required';
            }

            return errors;
        },
        onSubmit: (values) => {
            saveChanges(values)
        },
    });

    const saveChanges = async (editedEvent) => {
        const errors = await formik.validateForm(editedEvent);
        if (Object.keys(errors).length === 0) {

            if (eventType === EventTypeEnum.Event) {
                try {
                    await eventsStore.updateEvent(editedEvent);
                    history.push(`/events/${editedEvent.id}`);
                } catch (error) {
                    let err = error as any;
                    setErrorMessage(err.response?.data?.message || 'An error occurred');
                    return
                }
            }

            if (eventType === EventTypeEnum.FacebookEvent) {
                try {
                    const createdEventId = await fbEventsStore.createEventFromFBevent(editedEvent)
                    history.push(`/events/${createdEventId}`);
                } catch (error) {
                    let err = error as any;
                    setErrorMessage(err.response?.data?.message || 'An error occurred');
                    return
                }
            }
        } else {
            console.log('errors', errors);
        }
    };


    if (eventsStore.isLoading || fbEventsStore.isLoading) {
        return <LoadingView title="Details are loading..."/>;
    }


    if (editedEvent) {
        return (
            <Wrapper>
                <DetailsContainer>
                    <EventFormFields
                        event={editedEvent} 
                        onEventChange={handleEventChange} 
                        eventType={eventType} // Pass the event type
                        errors={formik.errors} 
                    />
                </DetailsContainer>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <Button
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        saveChanges(editedEvent)
                    }}
                >
                    Save changes
                </Button>
            </Wrapper>
        );
    }
    return <h1>NA</h1>;
});
const DetailsContainer = styled(Paper)`
    padding: 15px;
`;
const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
`;
const ErrorMessage = styled.p`
    color: red;
`;
export default EditEventPage;
