import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Coverage, COVERAGE_TYPE } from '@root/types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface OwnProps {
  coverage: Coverage;
  onChange(coverage: Coverage): void;
}

const CoverageForm: React.FC<OwnProps> = ({ coverage, onChange }) => {
  const [coverageType, setCoverageType] = useState(coverage.type);
  const [distance, setDistance] = useState(coverage.distance);
  const [country, setCountry] = useState(coverage.countryName);

  useEffect(() => {
    setCoverageType(coverage.type);
    setDistance(coverage.distance);
    setCountry(coverage.countryName);
  }, [coverage]);

  const onCoverageSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = COVERAGE_TYPE[event.target.value as string];
    setCoverageType(newType);
    onChange({
      ...coverage,
      type: newType,
    });
  };

  const onDistanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dist = parseFloat(event.target.value);
    setDistance(dist);
    onChange({
      ...coverage,
      distance: dist,
    });
  };

  const onCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const country = event.target.value;
    setCountry(country);
    onChange({
      ...coverage,
      countryName: country,
    });
  };

  return (
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <h4>Coverage:</h4>
        <FormControl>
          <InputLabel id="select-coverage">Type</InputLabel>
          <Select labelId="coverage-select-label" id="coverage-select" value={coverageType} onChange={onCoverageSelect}>
            <MenuItem value={COVERAGE_TYPE.ALL}>All</MenuItem>
            <MenuItem value={COVERAGE_TYPE.KM}>Km</MenuItem>
            <MenuItem value={COVERAGE_TYPE.COUNTRY}>Country</MenuItem>
          </Select>
        </FormControl>
        {coverageType === COVERAGE_TYPE.KM && (
            <TextField
                fullWidth
                id="distance"
                name="distance"
                label="Distance"
                onChange={onDistanceChange}
                value={distance}
            />
        )}
        {coverageType === COVERAGE_TYPE.COUNTRY && (
            <TextField
                fullWidth
                id="countryName"
                name="countryName"
                label="Country name"
                onChange={onCountryChange}
                value={country}
            />
        )}
      </div>
  );
};

const FormWrapper = styled.div`
  flex-direction: column;
  padding-horizontal: 20;
`;

export default CoverageForm;