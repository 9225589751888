import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from '@root/stores';

export class BucketStore {
  file?: File;
  fileUrl?: string;
  errorMessage?: string;
  isLoading?: boolean;

  private root: RootStore;

  constructor(rootStore: RootStore) {
    this.root = rootStore;

    makeAutoObservable(this);
  }

  setFile(file: File) {
    runInAction(() => {
      this.file = file;
    });
  }

  setFileUrl(url: string) {
    runInAction(() => {
      this.fileUrl = url;
    });
  }

  setLoading(loading: boolean) {
    runInAction(() => {
      this.isLoading = loading;
      this.errorMessage = undefined;
    });
  }

  showError(message: string) {
    runInAction(() => {
      this.errorMessage = message;
    });
  }

  async uploadFile(): Promise<string> {
    this.fileUrl = await this.upload(this.file);
    return this.fileUrl;
  }

  async deleteFile(): Promise<boolean> {
    this.setLoading(true);
    try {
      if (this.fileUrl) {
        await this.root.bucketApiService.deleteFile(this.fileUrl);
        runInAction(() => {
          this.file = undefined;
          this.fileUrl = undefined;
        });
        this.setLoading(false);
        return true;
      }
    } catch (error) {
      this.showError(error.message);
    }
    this.setLoading(false);
    return false;
  }

  async upload(file?: File): Promise<string> {
    this.setLoading(true);
    try {
      if (file) {
        const fileUrl = await (await this.root.bucketApiService.uploadFile(file)).data;
        return Promise.resolve(fileUrl);
      }
    } catch (error) {
      // todo add logger here
      this.showError('Could not upload');
    }
    this.setLoading(false);
    return Promise.resolve('');
  }
}
