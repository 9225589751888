import { DeleteReportApiResponse, ReportedApiResponse } from '@root/types';
import { network } from '@root/services/api/network';

const basePath = 'reports';

export class ReportsApiService {
  private readonly resource = {
    reportedEvents: `${basePath}/events`,
    reportedUsers: `${basePath}/users`,
    deleteReportForReportedUser: `${basePath}/user-report`,
    deleteReportForReportedEvent: `${basePath}/event-report`,
    deleteEvent: 'events',
    deleteUser: 'users',
  };

  async fetchReportedEvents(): Promise<ReportedApiResponse> {
    return network.get(this.resource.reportedEvents);
  }

  async fetchReportedUsers(): Promise<ReportedUsersApiResponse> {
    return network.get(this.resource.reportedUsers);
  }

  async deleteReportForReportedUser(reportId: string): Promise<DeleteReportApiResponse> {
    return network.delete(`${this.resource.deleteReportForReportedUser}/${reportId}`);
  }

  async deteleReportOfReportedEvent(reportId: string): Promise<DeleteReportApiResponse> {
    return network.delete(`${this.resource.deleteReportForReportedEvent}/${reportId}`);
  }

  async deleteEvent(eventId: string): Promise<DeleteReportApiResponse> {
    return network.delete(`${this.resource.deleteEvent}/${eventId}`);
  }

  async deleteUser(userId: string): Promise<DeleteReportApiResponse> {
    return network.delete(`${this.resource.deleteUser}/${userId}`);
  }
}
