// import { Event, EventDetails, LocalizedDetails } from '@root/types';
import {FBcollector} from "@root/types";
import {makeAutoObservable, runInAction} from "mobx";

import {RootStore} from "@root/stores";

export class FBcollectorsStore {
    collector?: FBcollector;
    collectors: FBcollector[] = [];

    isLoading = false;


    private root: RootStore;

    constructor(rootStore: RootStore) {
        this.root = rootStore;

        makeAutoObservable(this);
    }


    async fetchCollectors(): Promise<void> {
        this.setLoading(true);
        try {
            const response = await this.root.fbCollectorsApi.fetchCollectors();
            runInAction(() => {
                this.collectors = response.data.data;
            });
        } finally {
            this.setLoading(false);
        }
    }

    async delete(collectorId: string): Promise<void> {
        this.setLoading(true);
        try {
            const response = await this.root.fbCollectorsApi.delete(collectorId);
            if (response.status === 200) {
                runInAction(() => {
                    this.collectors = this.collectors.filter((collector) => collector.uid !== collectorId);
                });
            } else {
                console.error(
                    `Failed to delete collector with id: ${collectorId}. Server responded with status: ${response.status}`
                );
            }
        } finally {
            this.setLoading(false);
        }
    }


    async create(collector: FBcollector): Promise<void> {
        this.setLoading(true);
        try {
            const response = await this.root.fbCollectorsApi.create(collector);
            if (response.status === 200) {
                runInAction(() => {
                    this.collectors.push(response.data.data);
                });
            } else {
                console.error(
                    `Failed to create collector. Server responded with status: ${response.status}`
                );
            }
        } finally {
            this.setLoading(false);
        }
    }

    async update(collector: FBcollector): Promise<void> {
        this.setLoading(true);
        try {
            const response = await this.root.fbCollectorsApi.update(collector);
            if (response.status === 200) {
                runInAction(() => {
                    this.collectors = this.collectors.map((existingCollector) => {
                        if (existingCollector.uid === response.data.data.uid) {
                            return response.data.data;
                        }
                        return existingCollector;
                    });
                });
            } else {
                console.error(
                    `Failed to update collector with id: ${collector.uid}. Server responded with status: ${response.status}`
                );
            }
        } finally {
            this.setLoading(false);
        }
    }


    private setLoading(isLoading: boolean): void {
        this.isLoading = isLoading;
    }
}
