import React from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import MainNav from '@root/pages/main-nav';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '@root/components';
import LoginPage from '../login/login.page';

const browserHistory = createBrowserHistory();

const Layout = ({ children }) => (
  <Router history={browserHistory}>
    <>
      <MainNav />
      {children}
    </>
  </Router>
);

const RootPage = observer(() => {
  const { authStore } = useRootStore();

  if (authStore.isAuthenticated()) {
    return <Layout />;
  }
  return <LoginPage />;
});

export default RootPage;
