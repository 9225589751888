import {DeleteFBjobApiResponse, FBjobsApiResponse, FBjob, FBjobApiResponse} from '@root/types';
import { network } from '@root/services/api/network';


export class FBjobsApiService {
    private readonly resource = {
        jobs: '/jobs',
    };

    async fetchJobs(): Promise<FBjobsApiResponse> {
        return network.get(this.resource.jobs);
    }

    async delete(jobId: string): Promise<DeleteFBjobApiResponse> {
        return network.delete(`${this.resource.jobs}/${jobId}`);
    }

    async create(job: Omit<FBjob, 'uid'>): Promise<FBjobApiResponse> {
        return network.post(this.resource.jobs, job);
    }

    async update(job: FBjob): Promise<FBjobApiResponse> {
        return network.put(`${this.resource.jobs}/${job.uid}`, job);
    }
}