import { useField } from 'formik';
import { Grid, TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    error: {
        color: 'red',
    },
});

const FormikField = ({ label, name, type }: { label: string, name: string, type: string }) => {
    const classes = useStyles();
    const [field, meta] = useField(name);

    return (
        <Grid item>
            <TextField
                {...field}
                label={label}
                type={type}
                error={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
                FormHelperTextProps={{ className: meta.touched && meta.error ? classes.error : '' }}
            />
        </Grid>
    );
};

export default FormikField;