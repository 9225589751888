import { DeleteEventApiResponse, EventDetailsApiResponse, EventsApiResponse, LocalizedDetails } from '@root/types';
import { network } from '@root/services/api/network';

export class EventsApiService {
  private readonly resource = {
    events: '/events/all',
    event: '/events',
    editEvent: '/events/update',
    uploadPhoto: '/events/upload-photo',
    removeMember: '/events/delete-user',
  };
1
  async fetchEvents(): Promise<EventsApiResponse> {
    return network.get(this.resource.events);
  }

  async fetchEventDetails(id: string): Promise<EventDetailsApiResponse> {
    const params = {
      'id': id,
    };
    return network.get(`${this.resource.event}/${id}`);
  }

  async delete(eventId: string): Promise<DeleteEventApiResponse> {
    return network.delete(`${this.resource.event}/${eventId}`);
  }

  async deleteMember(eventId: string, memberId: string): Promise<string> {
    const body = {
      userId: memberId,
      eventId,
    };
    return network.post(this.resource.removeMember, body);
  }

  async updateEvent(event): Promise<void> {
    const {
      address,
      categoryId,
      details,
      end,
      id,
      maxParticipantsCount,
      start,
      type
    } = event;
    const body = {
      address,
      categoryId,
      details,
      end,
      id,
      maxParticipantsCount,
      start,
      type
    };
    return network.put(this.resource.editEvent, body);
  }

  async update( event) {
  console.log(event)
      const body =event.details;
    return network.put(this.resource.editEvent, body);
  }

  async updatePhoto(eventId: string, photo: File) {
    const formData = new FormData();
    formData.append('photo', photo);
    formData.append('eventId', eventId);
    return network.post(this.resource.uploadPhoto, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
