import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { LoadingView, useRootStore } from '@root/components';
import { Button, Paper } from '@material-ui/core';

const ProfilePage = observer(() => {
  const { authStore } = useRootStore();

  return (
    <Button
      type={'button'}
      style={{ marginTop: '23px' }}
      onClick={() => {
        authStore.logOut();
      }}
    >
      Log Out
    </Button>
  );
});

export default ProfilePage;
