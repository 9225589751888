export class UserStorage {
  save = (userId: string, authToken: string, refreshToken: string) => {
    localStorage.setItem('user_id', userId);
    this.saveTokens(authToken, refreshToken);
  };

  userId = () => localStorage.getItem('user_id');

  saveTokens = (authToken: string, refreshToken: string) => {
    localStorage.setItem('authToken', authToken);
    localStorage.setItem('refreshToken', refreshToken);
  };

  hasAuthToken = () => {
    const token = localStorage.getItem('authToken');
    return token && token.length;
  };

  getAccessToken = () => {
    const token = localStorage.getItem('authToken');
    return token;
  };

  clear = () => {
    localStorage.clear();
  };
}
