import { CategoriesApiResponse } from '@root/types';
import { network } from '../network';

export class SuggestionsApiService {
  private readonly resource = {
    waitingForReview: 'category/waiting-for-review/all',
  };

  async fetchWaitingForReviewCategories(): Promise<CategoriesApiResponse> {
    return network.get(this.resource.waitingForReview);
  }
}
