import type {AxiosResponse} from 'axios';
import {JtResponse} from '@root/types/api/api.types';
import {Event} from '@material-ui/icons';
import {LocalizedDetails} from '..';

// NB: this is an example of how to build app interfaces
// [NAME]ApiResponse should be used for data you get from your api call
// [NAME] your entity
// if back-end [NAME] isn't convenient for your UI layer consider re-mapping it on Data layer (your data stores, e.g. JobsStore)

export type EventsApiResponse = AxiosResponse<JtResponse<Event[]>>;

export type EventDetailsApiResponse = AxiosResponse<JtResponse<EventDetails>>;
export type FBeventDetailsApiResponse = AxiosResponse<JtResponse<FBeventDetails>>;


export type DeleteEventApiResponse = AxiosResponse<JtResponse<string>>;

export type FBeventsApiResponse = AxiosResponse<JtResponse<FBevent[]>>;

export type FBcollectorsApiResponse = AxiosResponse<JtResponse<FBcollector[]>>;
export type DeleteFBcollectorApiResponse = AxiosResponse<JtResponse<string>>;
export type FBcollectorApiResponse = AxiosResponse<JtResponse<FBcollector>>;

export type FBjobsApiResponse = AxiosResponse<JtResponse<FBjob[]>>;
export type FBjobApiResponse = AxiosResponse<JtResponse<FBjob>>;
export type DeleteFBjobApiResponse = AxiosResponse<JtResponse<string>>;
export type CreateFBjobApiResponse = AxiosResponse<JtResponse<FBjob>>;
export type UpdateFBjobApiResponse = AxiosResponse<JtResponse<FBjob>>;


export interface JtDateTime {
    date?: string;
    time?: string;
}

export interface Category {
    id: number;
    category_id: number;
    title: string;
    description: string;
    localizations_key: string;
    icon: string | null;
}

export interface Event {
    id: string;
    title: string;
    imageUrl: string;

    startDateTime: JtDateTime;
    endDateTime: JtDateTime;
    type: string;
    address: string;
    categoryIcon: string;
    categoryId: string;
    maxParticipantsCount: number;
    participantsCount: number;
    mainCategory: Category;
    subcategory: Category;
    category: Category;
}


export interface FBevent {
    id: string;
    name: string;
    description: string;
    job_name: string;
    creator: string;
    country: string;
    address: string;
    start_timestamp: string;
    end_timestamp: string;
}


export interface FBcollector {
    uid: string;
    collector: string;
    logger: string;
    store: string;
    journal: string;
    name: string,
    credentials: {
        username: string;
        password: string;
    };
    params: { [key: string]: any } | null;
}

export interface FBjob {
    uid: string;
    name: string | null,
    collector_uid: string;
    collector_name: string,
    collectorUid?: string;
    source: string;
    schedule: string;
    active: boolean;
}

export interface Participant {
    id: string;
    avatar: string;
    nickname: string;
}

export interface EventDetails extends Event {
    description: string;
    participants?: Participant[];
    participantsCount: number;
    maxParticipantsCount: number;
}

export interface FBeventDetails {
    id: string;
    name: string;
    description: string;
    creator: string;
    country: string;
    address: string;
    start_timestamp: string;
    end_timestamp: string;
}

export interface RqUpdateEvent {
    id: string;
    details: LocalizedDetails[];
}

export function mapDetailsToEvent(details: EventDetails): Event {
    const event: Event = {
        id: details.id,
        title: details.title,
        imageUrl: details.imageUrl,
        mainCategory: details.mainCategory,
        subcategory: details.subcategory,
        category: details.category,
        startDateTime: details.startDateTime,
        endDateTime: details.endDateTime,
        type: details.type,
        address: details.address,
        categoryIcon: details.categoryIcon,
        categoryId: details.categoryId,
        maxParticipantsCount: details.maxParticipantsCount,
        participantsCount: details.participantsCount,
    };
    return event;
}
