import ImageUploadView from "@root/components/image-upload/imageUpload.view";
import React, { useEffect, useState } from "react";
import { EventTypeEnum } from "@root/models/EventTypeEnum";
import { EventVisibilityEnum } from "@root/models/EventVisibilityEnum";
import { Box, Grid, TextField, MenuItem } from "@material-ui/core";
import { useRootStore } from '@root/components';
import styled from 'styled-components';

import CategorySelector from "@root/pages/events/components/category-selector";
import MaxCounterField from "@root/components/MaxCounterField";

interface Values {
    details: { locale: string, title: string, description: string }[],
    start: string | undefined;
    end: string | undefined;
    address: { address: string } | undefined;
    categoryId: string | number | null,
    maxParticipantsCount: string | number,
}

interface EventFormFieldsProps {
    event: any;
    onEventChange: (event: any) => void;
    eventType: EventTypeEnum;
    errors: Partial<Values>;
}

const EventFormFields: React.FC<EventFormFieldsProps> = ({
    event,
    onEventChange,
    eventType,
    errors
}) => {
    const { categoriesStore } = useRootStore();
    const [localEvent, setLocalEvent] = React.useState(event);
    const { eventsStore, fbEventsStore } = useRootStore();

    const [isLoading, setIsLoading] = useState(true);
    React.useEffect(() => {
        const formValues = event;
        if (formValues.maxParticipantsCount === 0) {
            formValues.maxParticipantsCount = '';
            setLocalEvent(formValues);
        } else {
            setLocalEvent(formValues);
        }
    }, [event]);
    const [locales, setLocales] = useState(['pl']);

    const addEnglishLocale = () => {
        setLocales(prevLocales => {

            if (prevLocales.includes('en')) {
                return prevLocales;
            }
            return [...prevLocales, 'en'];
        });
    };

    useEffect(() => {
        categoriesStore.initialyFetchCategories().finally(() => setIsLoading(false));
    }, []);

    const onPicture = (file: File) => {
        if (eventType === EventTypeEnum.Event) {
            eventsStore.setPicture(file)
        } else if (eventType === EventTypeEnum.FacebookEvent) {
            fbEventsStore.setPicture(file)
        }
    };
    const handleDetailsChange = (index: number, field: string, value: string, locale: string) => {
        const newDetails = [...localEvent.details];
        let detailIndex = newDetails.findIndex(detail => detail.locale === locale);
        if (detailIndex === -1) {
            newDetails.push({ locale, [field]: value });
        } else {
            newDetails[detailIndex][field] = value;
            if (!newDetails[detailIndex].title && !newDetails[detailIndex].description) {
                newDetails.splice(detailIndex, 1);
            }
        }
        const updatedEvent = { ...localEvent, details: newDetails };
        setLocalEvent(updatedEvent);
        onEventChange(updatedEvent);
    };
    const handleEventFieldChange = (field: string, value: any) => {
        const updatedEvent = { ...localEvent, [field]: value };
        setLocalEvent(updatedEvent);
        onEventChange(updatedEvent);
        console.log(updatedEvent)
    };

    if (isLoading) {
        return <h1>Loading...</h1>
    }

    if (categoriesStore.categories.length === 0) {
        return <h1>No categories found.</h1>
    }
    return (
        <>
            <h1>
                {eventType === EventTypeEnum.FacebookEvent ? 'Creating event from Facebook event' :
                    eventType === EventTypeEnum.Event ? 'Editing event' :
                        'Creating new event'}
            </h1>
            {['pl', 'en'].map((locale, index) => {
                let detail = localEvent.details.find(detail => detail.locale === locale);
                if (!detail) {
                    detail = {
                        locale,
                        title: "",
                        description: ""
                    };
                }

                return (
                    <Box key={index} marginBottom={2}>
                        <p>
                            Locale {detail.locale}:
                        </p>
                        <TextField
                            label="Title"
                            value={detail.title}
                            onChange={(e) => handleDetailsChange(index, 'title', e.target.value, locale)}
                            error={Boolean(errors.details?.[index]?.title)}
                            helperText={errors.details?.[index]?.title}
                            fullWidth
                        />
                        <DescriptionWrapper>
                            <CounterPosition>
                                <MaxCounterField currentLength={detail.description.length} maxLength={1000} />
                            </CounterPosition>
                            <TextField
                                label="Description"
                                value={detail.description}
                                inputProps={{ maxLength: 1000 }}
                                onChange={(e) => handleDetailsChange(index, 'description', e.target.value, locale)}
                                error={Boolean(errors.details?.[index]?.description)}
                                helperText={errors.details?.[index]?.description}
                                fullWidth
                                multiline
                                minRows={4}
                                InputProps={{
                                    style: {
                                        paddingRight: '85px',
                                    },
                                }}
                            />
                        </DescriptionWrapper>

                    </Box>
                );
            })}
            <h3>Select category</h3>
            <CategorySelector
                categories={categoriesStore.categories}
                onCategorySelect={(category) => handleEventFieldChange('categoryId', category.id)}
                error={errors.categoryId}
            />

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box marginBottom={2}>
                        <TextField
                            label="Max Participants Count"
                            inputProps={{
                                maxLength: 4
                            }}
                            value={localEvent.maxParticipantsCount}
                            onChange={(e) => handleEventFieldChange('maxParticipantsCount', Number(e.target.value) > 0 ? Number(e.target.value) : '')}
                            onKeyDown={(e) => {
                                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace') {
                                    e.preventDefault();
                                }
                            }}
                            fullWidth
                            error={Boolean(errors.maxParticipantsCount)}
                            helperText={errors.maxParticipantsCount}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box marginBottom={2}>
                        <TextField
                            label="Visibility"
                            select
                            value={localEvent.type}
                            onChange={(e) => handleEventFieldChange('type', Number(e.target.value))}
                            fullWidth
                        >
                            <MenuItem value={EventVisibilityEnum.PRIVATE}>Private</MenuItem>
                            <MenuItem value={EventVisibilityEnum.FOR_FRIENDS}>For friends</MenuItem>
                            <MenuItem value={EventVisibilityEnum.PUBLIC}>Public</MenuItem>
                        </TextField>
                    </Box>
                </Grid>
            </Grid>
            <Box marginBottom={2}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Start"
                            type="datetime-local"
                            value={localEvent.start}
                            onChange={(e) => handleEventFieldChange('start', e.target.value)}
                            error={Boolean(errors.start)}
                            helperText={errors.start}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="End"
                            type="datetime-local"
                            value={localEvent.end}
                            onChange={(e) => handleEventFieldChange('end', e.target.value)}
                            error={Boolean(errors.end)}
                            helperText={errors.end}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box marginBottom={2}>
                <TextField
                    label="Address"
                    value={localEvent.address.address}
                    onChange={(e) => handleEventFieldChange('address', { address: e.target.value })}
                    error={Boolean(errors.address?.address)}
                    fullWidth
                />
            </Box>
            <ImageUploadView buttonText="Change picture" onImage={(file) => onPicture(file)} />
        </>
    );
}

const DescriptionWrapper = styled.div`
    display: block;
    position: relative;
`;

const CounterPosition = styled.div`
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
`;



export default EventFormFields;