import {FBjob} from "@root/types";
import {makeAutoObservable, runInAction} from "mobx";
import {RootStore} from "@root/stores";

export class FBjobsStore {
    job?: FBjob;
    jobs: FBjob[] = [];
    isLoading = false;

    private root: RootStore;

    constructor(rootStore: RootStore) {
        this.root = rootStore;
        makeAutoObservable(this);
    }

    async fetchJobs(): Promise<void> {
        this.setLoading(true);
        try {
            const response = await this.root.fbJobsApi.fetchJobs();
            runInAction(() => {
                this.jobs = response.data.data;
            });
        } finally {
            this.setLoading(false);
        }
    }

    async delete(uid: string): Promise<void> {
        this.setLoading(true);
        try {
            const response = await this.root.fbJobsApi.delete(uid);
            if (response.status === 200) {
                runInAction(() => {
                    this.jobs = this.jobs.filter((job) => job.uid !== uid);
                });
            } else {
                console.error(
                    `Failed to delete job with uid: ${uid}. Server responded with status: ${response.status}`
                );
            }
        } finally {
            this.setLoading(false);
        }
    }

    async create(job: Omit<FBjob, 'uid'>): Promise<void> {
        this.setLoading(true);
        try {
            const response = await this.root.fbJobsApi.create(job);
            if (response.status === 200) {
                runInAction(() => {
                    this.jobs.push(response.data.data);
                });
            } else {
                console.error(
                    `Failed to create job. Server responded with status: ${response.status}`
                );
            }
        } finally {
            this.setLoading(false);
        }
    }

    async update(job: FBjob): Promise<void> {
        this.setLoading(true);
        try {
            const response = await this.root.fbJobsApi.update(job);
            if (response.status === 200) {
                runInAction(() => {
                    this.jobs = this.jobs.map((existingJob) => {
                        if (existingJob.uid === response.data.data.uid) {
                            return response.data.data;
                        }
                        return existingJob;
                    });
                });
            } else {
                console.error(
                    `Failed to update job with uid: ${job.uid}. Server responded with status: ${response.status}`
                );
            }
        } finally {
            this.setLoading(false);
        }
    }

    private setLoading(isLoading: boolean): void {
        this.isLoading = isLoading;
    }
}