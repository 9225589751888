import { LoadingView, useRootStore } from '@root/components';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import EventCard from '@root/pages/events/components/event-card';
import styled from 'styled-components';
import { Button, Divider, Link } from '@material-ui/core';
import PromptDialog from '@root/components/prompt-dialog';
import { Report, ReportUser } from '@root/types';
import { ReportsStore } from '@root/stores/reports/reports.store';
import { Avatar } from '@material-ui/core';

const DIALOGS_DATA = [
  {
    type: 'event',
  },
  {
    type: 'report',
  },
];

type EventReportProps = {
  reportsStore: ReportsStore;
  reports: Report[];
};

const EmptyReports = () => <h4>Empty</h4>;

const EventReports = ({ reportsStore, reports }: EventReportProps) => {
  const [promptOpen, setPromptOpen] = useState(false);

  const handleClickOpen = () => {
    setPromptOpen(true);
  };

  const handleClose = () => {
    setPromptOpen(false);
  };

  const deleteReport = (reportId: string) => {
    reportsStore.deteleReportOfReportedEvent(reportId);
    handleClose();
  };

  const deleteEvent = (eventId: string) => {
    reportsStore.deleteEvent(eventId);
    handleClose();
  };

  if (reports.length === 0) {
    return <EmptyReports />;
  }

  return (
    <>
      {reports.map((report) => {
        const { reportId, event } = report;
        return (
          <ReportWrapper>
            <ReportDetailsWrapper>
              <Link
                underline="none"
                component={RouterLink}
                to={{
                  pathname: `events/${event.id}`,
                  query: { id: event.id },
                }}
              >
                <EventCard key={event.id} event={event} />
              </Link>
              <ReportReasonWrapper>
                <h3>Reason:</h3>
                {report.reason}
              </ReportReasonWrapper>
            </ReportDetailsWrapper>
            <ReportsActionsWrapper>
              <Button
                onClick={() => {
                  deleteReport(reportId);
                }}
              >
                Delete report
              </Button>
              <Button
                onClick={() => {
                  deleteEvent(event.id);
                }}
              >
                Delete event
              </Button>
            </ReportsActionsWrapper>
            <Divider />
          </ReportWrapper>
        );
      })}
      <PromptDialog
        open={promptOpen}
        title="Are you sure?"
        description="Item cannot be restored."
        approveButtonTitle="Delete"
        onApproveButtonClick={() => {
          console.log('on detele report!');
        }}
      />
    </>
  );
};

type UserReportsProps = {
  reportsStore: ReportsStore;
  reports: ReportUser[];
};

const UserReports = ({ reportsStore, reports }: UserReportsProps) => {
  const [promptOpen, setPromptOpen] = useState(false);

  const handleClickOpen = () => {
    setPromptOpen(true);
  };

  const handleClose = () => {
    setPromptOpen(false);
  };

  const deleteReport = (reportId: string) => {
    reportsStore.deteleReportOfReportedUser(reportId);
    handleClose();
  };

  const deleteUser = (userId: string) => {
    reportsStore.deleteUser(userId);
    handleClose();
  };

  if (reports.length === 0) {
    return <EmptyReports />;
  }

  return (
    <>
      {reports.map((report) => {
        const { id, reportedUser } = report;
        return (
          <ReportWrapper>
            <ReportDetailsWrapper>
              <>
                <h4>{`Reported user: ${reportedUser.nickName ?? ''}`}</h4>
                <Avatar src={reportedUser.photoUrl} />
              </>
              <ReportReasonWrapper>
                <h4>Reason: </h4>
                {report.reason}
              </ReportReasonWrapper>
            </ReportDetailsWrapper>
            <ReportsActionsWrapper>
              <Button
                onClick={() => {
                  deleteReport(id);
                }}
              >
                Delete report
              </Button>
              <Button
                onClick={() => {
                  deleteUser(reportedUser.id);
                }}
              >
                Delete user
              </Button>
            </ReportsActionsWrapper>
            <Divider />
          </ReportWrapper>
        );
      })}
      <PromptDialog
        open={promptOpen}
        title="Are you sure?"
        description="Item cannot be restored."
        approveButtonTitle="Delete"
        onApproveButtonClick={() => {
          console.log('on detele user!');
        }}
      />
    </>
  );
};

const ReportsPage = observer(() => {
  const { reportsStore, profileStore } = useRootStore();

  useEffect(() => {
    reportsStore.fetchAllReports();
  }, []);

  if (reportsStore.isLoading) {
    return <LoadingView title="Reports are loading..." />;
  }

  return (
    <>
      <h3>Reported events</h3>
      <EventReports reports={reportsStore.reports} reportsStore={reportsStore} />
      <Divider />
      <h3>Reported users</h3>
      <UserReports reports={reportsStore.reportedUsers} reportsStore={reportsStore} />
    </>
  );
});

const ReportWrapper = styled.div`
  flex: 1;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const ReportDetailsWrapper = styled.div`
  flex: 1;
  flex-direction: row;
`;

const ReportsActionsWrapper = styled.div``;

const ReportReasonWrapper = styled.div`
  display: flex;
  margin-left: 5px;
  align-items: baseline;
`;

export default ReportsPage;
