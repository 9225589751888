import { AuthService } from '@root/services/api/auth-api/auth-api.servise';
import { makeAutoObservable } from 'mobx';
import {UserStorage} from "@root/services/storage/userStorage";

export class AuthStore {
  private userId = '';
  private authenticated = false;
  private loading = false;

  constructor(private readonly authService: AuthService, private readonly userStorage: UserStorage) {
    makeAutoObservable(this);
    this.authenticated = !!this.getAccessToken();
  }

  async logIn(login: string, password: string): Promise<void> {
    this.loading = true;
    try {
      const response = await this.authService.login(login, password);
      const { authToken, refreshToken, userId } = response.data.data;
      this.userId = userId;
      this.userStorage.save(userId, authToken, refreshToken);
      if (authToken) {
        this.setAuthenticated(true);
      } else {
        this.setAuthenticated(false);
      }
    } finally {
      this.loading = false;
    }
  }

  async logOut(): Promise<void> {
    this.userStorage.clear();
    this.setAuthenticated(false);
  }

  private setAuthenticated(authenticated: boolean) {
    this.authenticated = authenticated;
  }

  getAccessToken() {
    return this.userStorage.getAccessToken();
  }

  isAuthenticated() {
    return this.authenticated;
  }

  isLoading() {
    return this.loading;
  }
}
