import { appConfig } from '@root/boot/app-config';
import { StatusCodes } from '@root/types';
import Axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

const { API_URL } = appConfig;
export const BASE_ADMIN_URL = `${API_URL}/admin/v1/`;
export const BASE_API_URL = `${API_URL}/v1/`;

// this is an example of back-end error response
export interface ResponseError {
  code: string;
  message: string;
}

interface CustomHandlers {
  useCustomResponseErrorHandler?: boolean;
}

interface ApiInstanceOptions {
  baseURL: string;
  withCredentials: boolean;
}

export class ApiConfig {
  httpRequest: AxiosInstance;

  // you can use your AuthStore here to check token on every request as an option
  // you'll need to add it to applyRequestInterceptors()

  constructor(options: ApiInstanceOptions) {
    this.httpRequest = Axios.create(options);
    this.applyResponseInterceptors(this.httpRequest);
  }

  private applyResponseInterceptors(instance: AxiosInstance): void {
    instance.interceptors.request.use(function (config) {
      const authToken = localStorage.getItem('authToken');
      config.headers.Authorization = authToken ? `Bearer ${authToken}` : '';
      return config;
    });
    instance.interceptors.response.use(
      (resp: AxiosResponse) => resp,
      async (error: AxiosError<unknown>) => {
        if (Axios.isCancel(error)) {
          return Promise.reject({
            response: {
              status: StatusCodes.ClientClosedRequest,
              data: { errors: [error.message] },
            },
          });
        }

        if (this.isCustomError(error)) {
          return Promise.reject(error);
        }

        this.showErrorNotifications(error);

        return Promise.reject(error);
      }
    );
  }

  private isCustomError(error: AxiosError): boolean | undefined {
    return (
      Boolean(error.config?.data) && (JSON.parse(error.config.data) as CustomHandlers).useCustomResponseErrorHandler
    );
  }

  private showErrorNotifications(error: AxiosError): void {
    // this is an example of how back-end can return error response
    if (error.response) {
      // TODO log error into analytics
      // TODO: create a notification service which will handle error messages for you every time request fails
      // e.g.
      // notificationService.showError({
      //   id: e.code,
      //   values: e.values,
      //   defaultMessage: e.message,
      // });
    } else {
      // TODO log error into analytics
    }
  }
}
