import React, { useCallback, useEffect } from 'react';
import { Formik, Form, FieldArray, FormikContextType } from 'formik';
import * as Yup from 'yup';
import { Button, Card, CardContent, InputLabel, MenuItem, Select, TextField, FormControl } from '@material-ui/core';
import { LocalizedDetails } from '@root/types';
import { FormikEffect } from '@root/components/FormikEffect';

interface OwnProps {
  details?: LocalizedDetails[];
  editableLocalization: boolean;
  onChange(details: LocalizedDetails[]): void;
}

interface FormValues {
  details: LocalizedDetails[];
}

const validationSchema = Yup.object().shape({
  details: Yup.array().of(
    Yup.object().shape({
      localizations_key: Yup.string().min(2).required('Locale is required.'),
      title: Yup.string().min(1).max(256).required('Title is required.'),
      description: Yup.string().min(4).max(1000).required('Description is required.'),
      imageUrl: Yup.string().required('Image Url is required.'),
    })
  ),
});

const LocalizedDetailsForm = (props: OwnProps) => {
  const [details, setDetails] = React.useState(null);

  const initialValues: FormValues = {
    details: props.details
      ? props.details
      : [
          {
            locale: 'pl',
            title: '',
            description: '',
          },
        ],
  };

  const handleFormChange = useCallback(({ values, isValid, dirty }: FormikContextType<FormValues>) => {
    setDetails(values.details);
    props.onChange(values.details);
  }, []);

  return (
    <div>
      <h2>Details:</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {}}
        render={({ values, touched, errors, setTouched, handleSubmit, handleChange, handleBlur, isValid }) => (
          <Form>
            <FormikEffect<FormValues> onChange={handleFormChange} />
            <FieldArray
              name="details"
              render={(arrayHelpers) => (
                <div>
                  {values.details.map((detail, i) => (
                    <Card key={i} style={{ marginBottom: '10px' }}>
                      <CardContent>
                        <FormControl style={{ display: 'flex' }}>
                          <InputLabel id="locale-select-label">Сhoose locale</InputLabel>
                          <Select
                            labelId="locale-select-label"
                            id={`details.${i}.localizations_key`}
                            name={`details.${i}.localizations_key`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={detail.localizations_key}
                          >
                            <MenuItem value="pl">pl</MenuItem>
                            <MenuItem value="en">en</MenuItem>
                          </Select>
                        </FormControl>
                        <TextField
                          fullWidth
                          id={`details.${i}.title`}
                          name={`details.${i}.title`}
                          label="Title"
                          onChange={handleChange}
                          //onBlur={() => setTouched({ ...touched, locale: true })}
                          onBlur={handleBlur}
                          value={detail.title}
                        />
                        <TextField
                          fullWidth
                          id={`details.${i}.description`}
                          name={`details.${i}.description`}
                          label="Description"
                          onChange={handleChange}
                          //onBlur={() => setTouched({ ...touched, locale: true })}
                          onBlur={handleBlur}
                          value={detail.description}
                        />
                        {props.editableLocalization && <Button onClick={() => arrayHelpers.remove(i)}>Remove</Button>}
                      </CardContent>
                    </Card>
                  ))}
                  {props.editableLocalization && (
                    <div>
                      <Button onClick={() => arrayHelpers.push('')}>Add localization</Button>
                    </div>
                  )}
                </div>
              )}
            />
          </Form>
        )}
      />
    </div>
  );
};

export default LocalizedDetailsForm;
