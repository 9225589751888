import React from 'react';
import { Category } from '@root/types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  createStyles,
  Link,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import AvatarView from '@root/components/avatar-view';

interface CategoryCardProps {
  category: Category;
  children?: React.ReactNode;
  onAdd(id: string): void;
  onDelete(id: string): void;
  renderSubCategories?(subCategories: Category[] | undefined): JSX.Element | null; // Add renderSubCategories prop
}


const CategoryCard = ({ category, children, onAdd, onDelete }: CategoryCardProps) => {
  const classes = useStyles();
  const { title, description, icon, picture, subCategories } = category;

  const renderSubCategories = (subCategories: Category[] | undefined) => {
    if (subCategories && subCategories.length > 0) {
      return subCategories.map((subCat) => (
        <CategoryCard
          key={subCat.id}
          category={subCat}
          children={children}
          onAdd={onAdd}
          onDelete={onDelete}
          renderSubCategories={renderSubCategories} // Pass the renderSubCategories function recursively
        />
      ));
    }
    return null;
  };
  
  

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.root}>
        <Link
          underline="none"
          component={RouterLink}
          to={{
            pathname: `categories/${category.id}`,
            query: { id: category.id },
          }}
        >
          Details
        </Link>
        {icon && (
          <div style={{ width: '100px' }}>
            <AvatarView src={icon} />
          </div>
        )}
        {picture && (
          <div style={{ width: '200px' }}>
            <AvatarView src={picture} />
          </div>
        )}
        {description && <Typography className={classes.description}>{description}</Typography>}
        {renderSubCategories(subCategories)}
        {children}
        <Button
          onClick={() => {
            onAdd(category.id);
          }}
        >
          Add
        </Button>
        <Button
          onClick={() => {
            onDelete(category.id);
          }}
        >
          Delete
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      flex: 1,
      flexFlow: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    description: {
      fontSize: theme.typography.pxToRem(10),
    },
  })
);

export default React.memo(CategoryCard);
