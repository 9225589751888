/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useRootStore } from '@root/components';
import { observer } from 'mobx-react-lite';
import { LoadingView, ContainerWrapper } from '@root/components';
import styled from 'styled-components';
import { Button, IconButton } from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { routesConfig } from '@root/services';
import { Alert, AlertTitle } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import CategoryCard from './components/category-card';

const CategoriesPage = observer(() => {
  const { categoriesStore } = useRootStore();
  const history = useHistory();
  useEffect(() => {
    categoriesStore.initialyFetchCategories();
  }, []);

  if (categoriesStore.isLoading) {
    return <LoadingView title="Loading..." />;
  }

  const addCategory = (
    <AddCategoryWrapper>
      <Link underline="none" component={RouterLink} to={{ pathname: routesConfig.createCategory.path() }}>
        <Button>Add main category</Button>
      </Link>
    </AddCategoryWrapper>
  );

  if (categoriesStore.categories.length === 0) {
    return (
      <>
        <h1>No categories found.</h1>
        {addCategory}
      </>
    );
  }

  if (categoriesStore.error) {
    return (
      <Alert
        severity="error"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              categoriesStore.clearErrors();
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle>Error</AlertTitle>
        {categoriesStore.error}
      </Alert>
    );
  }

  return (
    <ContainerWrapper>
      {addCategory}
      {categoriesStore.categories.map((category) => (
        <CategoryCard
          key={category.id}
          category={category}
          onAdd={(id) => {
            history.push({
              pathname: routesConfig.createCategory.path(),
              state: {
                parentCategoryId: id,
              },
            });
          }}
          onDelete={(id) => categoriesStore.delete(id)}
        />
      ))}
    </ContainerWrapper>
  );
});

const AddCategoryWrapper = styled.div`
  flex: 1;
  flex-direction: column-reverse;
`;

export default CategoriesPage;
