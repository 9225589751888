import React from 'react';
import { Event } from '@root/types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import AvatarView from '@root/components/avatar-view';
import Grid from '@material-ui/core/Grid';
import { Theme } from '@material-ui/core';
import eventPlaceholder from '../../../assets/eventPlaceholder.png';

interface EventCardProps {
  event: Event;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: { display: 'flex', flexFlow: 'row', margin: '5px' },
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
    },
    image: {
      width: 128,
      height: 128,
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  })
);

function EventCard({ event }: EventCardProps) {
  const {
    title,
    imageUrl,
    startDateTime,
    address,
    participantsCount,
    maxParticipantsCount,
  } = event;

  const mainCategory = event.mainCategory?.title;
  const category = event.category?.title;
  const subcategory = event.subcategory?.title;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography component="h5" variant="h5" noWrap>
          {title}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {mainCategory}/{category}/{subcategory}
        </Typography>
        <Grid container spacing={2}>
          <Grid item style={{ height: 'auto', width: '540px' }}>
            {imageUrl ? <AvatarView src={imageUrl} /> : <AvatarView src={eventPlaceholder} alt="No image" />}
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1">
                  <>
                    {startDateTime && startDateTime.date && startDateTime.date}{' '}
                    {startDateTime && startDateTime.time && startDateTime.time}
                  </>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {address}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {participantsCount}/{maxParticipantsCount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default React.memo(EventCard);
