import React from 'react';
import { observer } from 'mobx-react-lite';
import { LoadingView, useRootStore } from '@root/components';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Button, TextField } from '@material-ui/core';
import * as Yup from 'yup';

const validationSchemaSignIn = Yup.object().shape({
  login: Yup.string().min(2).max(64).required('The field cannot be empty'),
  password: Yup.string().min(2, 'Too Short!').max(32, 'Too Long!').required('The field cannot be empty'),
});

const LoginPage = observer(() => {
  const { authStore } = useRootStore();

  if (authStore.isLoading()) {
    return <LoadingView title="Loading" />;
  }

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          login: '',
          password: '',
        }}
        validationSchema={validationSchemaSignIn}
        onSubmit={(values) => {
          authStore.logIn(values.login, values.password);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit }) => (
          <div
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign: 'center' }}
          >
            <div>
              <h2>Login</h2>
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                name="login"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.login}
              />
              {touched.login && errors.login && <div> {errors.login} </div>}
              <h2>Password</h2>
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              {touched.password && errors.password && <div> {errors.password} </div>}
              <div style={{ marginTop: '25px' }}>
                <Button type="submit" onClick={handleSubmit} disabled={!isValid}>
                  Sign In
                </Button>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </FormWrapper>
  );
});

const FormWrapper = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default LoginPage;
