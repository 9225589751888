import { Category } from '@root/types';
import { makeAutoObservable, runInAction } from 'mobx';

import { RootStore } from '@root/stores';

export class CategoriesStore {
  categories: Category[] = [];
  category?: Category;
  isLoading = false;
  shouldGoBack = false;
  message = '';
  error = '';

  icon?: File;
  picture?: File;

  private root: RootStore;

  constructor(rootStore: RootStore) {
    this.root = rootStore;
    makeAutoObservable(this);
  }

  async initialyFetchCategories() {
    this.root.createCategoryStore.initState();
    this.root.categoryDetailsStore.initState();
    this.shouldGoBack = false;
    await this.fetchCategories();
  }

  async fetchCategories(lang: string = 'EN'): Promise<void> {
    this.setLoading(true);
    try {
      const response = await this.root.categoriesApi.fetchCategories(lang);
      this.categories = response.data.data;
    } finally {
      this.setLoading(false);
    }
  }

  clearErrors() {
    runInAction(() => {
      this.error = '';
    });
  }

  async delete(id: string): Promise<void> {
    this.setLoading(true);
    this.shouldGoBack = false;

    try {
      const response = await this.root.categoriesApi.deleteCategory(id);
      runInAction(() => {
        this.message = response.data.data;
      });
      try {
        await this.fetchCategories();
      } catch (e) {
        console.log(e);
      }
      this.shouldGoBack = true;
    } catch (error) {
      runInAction(() => {
        this.error = `Could not delete category. ${error.message}`;
      });
    } finally {
      this.setLoading(false);
    }
  }

  private setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  findById(id: string): Category | undefined {
    let cat: Category | undefined;
    this.categories.forEach((mainCategory) => {
      if (mainCategory.id === id) {
        cat = mainCategory;
      }
      mainCategory.subCategories?.forEach((category) => {
        if (category.id === id) {
          cat = category;
        }
        category.subCategories?.forEach((subCat) => {
          if (subCat.id === id) {
            cat = subCat;
          }
        });
      });
    });
    return cat;
  }
}
