import React, { useEffect } from 'react';
import { ContainerWrapper, LoadingView, useRootStore } from '@root/components';
import { observer } from 'mobx-react-lite';
import UserCard from './components/user-card';
import { InputAdornment, TextField } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import LoginPage from '@root/pages/login/login.page';

const UsersPage = observer(() => {
  const { usersStore, profileStore } = useRootStore();

  useEffect(() => {
    usersStore.fetchUsers();
  }, []);

  if (usersStore.areUsersLoading) {
    return <LoadingView title="Users are loading..." />;
  }

  if (!localStorage.getItem('authToken')) {
    return <LoginPage />;
  }

  const userSearchField = (
    <ContainerWrapper>
      <TextField
        fullWidth
        type="search"
        name="nickName"
        onChange={(event) => {
          const { value } = event.target;
          usersStore.localSearch(value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </ContainerWrapper>
  );

  return (
    <div style={{ display: 'flex', flexFlow: 'column', margin: '5px' }}>
      {userSearchField}
      {usersStore.users.length === 0 ? (
        <h3>Empty</h3>
      ) : (
        usersStore.users.map((user) => (
          <UserCard key={user.id} user={user} onDelete={(id) => usersStore.deleteUser(id)} />
        ))
      )}
    </div>
  );
});

export default UsersPage;
