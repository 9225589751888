import React, { useEffect, useState } from 'react';
import { Button, Paper } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LoginPage from '@root/pages/login/login.page';
import { PolicyService } from '@root/services/api/policy-api.service';
import RichtextEditor from '@root/components/RichtextEditor';

const PaperWrapper = ({ children }) => (
  <Paper style={{ padding: 20, marginTop: 10 }} elevation={1}>
    {children}
  </Paper>
);

PaperWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

const policyService = new PolicyService();

const PolicyPage = () => {
  const [loading, setLoading] = useState(false);
  const [policy, setPolicy] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const resp = await policyService.policy();
      setPolicy(resp.data?.data?.policy || '');
      setLoading(false);
    };
    fetchData().catch((err) => {
      console.log(err);
      setLoading(false);
    });
  }, []);

  if (!localStorage.getItem('authToken')) {
    return <LoginPage />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const submitPolicy = async (policyText: string) => {
    setLoading(true);
    const html = `
    <!DOCTYPE html>
    <html lang="">
    <head><title></title></head>
    <body>
    ${policyText}
    </body>
    </html>
`;
    const resp = await policyService.updatePolicy(html);
    setLoading(false);
  };

  return (
    <FormWrapper>
      <PaperWrapper>
        <div>
          <h1>Policy</h1>
          <RichtextEditor
            content={policy}
            onChange={(content: string) => {
              setPolicy(content);
            }}
          />
        </div>
      </PaperWrapper>
      <div style={{ marginTop: '25px' }}>
        <Button type="submit" onClick={() => submitPolicy(policy)}>
          Submit
        </Button>
      </div>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  flex-direction: column;
  padding-horizontal: 20;
`;

export default PolicyPage;
