import type { AxiosResponse } from 'axios';
import { JtResponse } from '@root/types';

export type ProfileApiResponse = AxiosResponse<JtResponse<Profile>>;

export type LogOutApiResponse = AxiosResponse<JtResponse<string>>;

export interface Profile {
  userId: string;
  authToken: string;
  refreshToken: string;
  status: string;
}
