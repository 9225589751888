import {DeleteFBcollectorApiResponse, FBcollectorsApiResponse, FBcollector, FBcollectorApiResponse} from '@root/types';
import { network } from '@root/services/api/network';

export class FBcollectorsApiService {
    private readonly resource = {
        collectors: '/collectors',
    };

    async fetchCollectors(): Promise<FBcollectorsApiResponse> {
        return network.get(this.resource.collectors);
    }


    async delete(collectorId: string): Promise<DeleteFBcollectorApiResponse> {
        return network.delete(`${this.resource.collectors}/${collectorId}`);
    }


    async create(collector: FBcollector): Promise<FBcollectorApiResponse> {
        return network.post(this.resource.collectors, collector);
    }
    async update(collector: FBcollector): Promise<FBcollectorApiResponse> {
        return network.put(`${this.resource.collectors}/${collector.uid}`, collector);
    }
}
