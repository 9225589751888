import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { LoadingView, useRootStore } from '@root/components';
import { Button, Paper } from '@material-ui/core';
import { useParams } from 'react-router';
import { Formik } from 'formik';
import ShortCategoryCard from '@root/pages/category/components/short-category-card';
import { routesConfig } from '@root/services';
import Link from '@material-ui/core/Link';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import LocalizedDetailsForm from './create-category/components/localizedDetails.form';
import { COVERAGE_TYPE, LocalizedDetails, Coverage } from '@root/types';
import ImageUploadView from '@root/components/image-upload/imageUpload.view';
import CoverageForm from './create-category/components/coverage.form';

const CategoryDetailsPage = observer(() => {
    const { categoryDetailsStore, categoriesStore } = useRootStore();
    const { categoryId } = useParams();
    const [formDetails, setFormDetails] = useState<LocalizedDetails[] | null>(null);
    const [coverage, setCoverage] = useState<Coverage>({
        type: COVERAGE_TYPE.ALL,
        distance: 0,
        countryName: '',
    });
    const history = useHistory();

    useEffect(() => {
        categoryDetailsStore.fetchCategoryDetails(categoryId);
    }, [categoryId, categoryDetailsStore]);

    useEffect(() => {
        if (categoryDetailsStore.categoryDetails) {
            setFormDetails(categoryDetailsStore.categoryDetails.details);
            setCoverage(categoryDetailsStore.categoryDetails.settings.coverage);
        }
    }, [categoryDetailsStore.categoryDetails]);

    const { categoryDetails, isLoading, redirectTocategoriesList } = categoryDetailsStore;

    if (redirectTocategoriesList) {
        history.push({ pathname: routesConfig.categories.path() });
    }

    if (isLoading || categoriesStore.isLoading) {
        return <LoadingView title="Details are loading..." />;
    }

    const onPicture = (file: File) => {
        categoryDetailsStore.picture = file;
    };

    const onIcon = (file: File) => {
        categoryDetailsStore.icon = file;
    };

    const ImageCard = (picture?: string) => (
        <Paper style={{ padding: '20px' }}>
            <h2>Photo:</h2>
            <ImageUploadView imageUrl={picture} buttonText="Choose picture" onImage={(file) => onPicture(file)} />
        </Paper>
    );

    const IconCard = (icon: string) => (
        <Paper style={{ padding: '20px', display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <h2>Icon:</h2>
                <ImageUploadView imageUrl={icon} buttonText="Choose icon" onImage={(file) => onIcon(file)} />
            </div>
        </Paper>
    );

    if (categoryDetails) {
        const { icon, picture, details, subCategories } = categoryDetails;

        return (
            <>
                <Formik
                    initialValues={{ details, coverage }}
                    onSubmit={(values) => {
                        if (formDetails != null) {
                            const payload = {
                                existingId: categoryId,
                                details: formDetails,
                                categoryCoverage: coverage,
                            };
                            categoryDetailsStore.editCategory(payload);
                        }
                    }}
                >
                    {({ handleSubmit }) => (
                        <>
                            <div>
                                <Button type="submit" onClick={handleSubmit}>
                                    Save
                                </Button>
                                <Link underline="none" component={RouterLink} to={{ pathname: routesConfig.categories.path() }}>
                                    <Button
                                        type="button"
                                        onClick={() => {
                                            categoriesStore.delete(categoryId);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </Link>
                            </div>
                            <LocalizedDetailsForm
                                details={details}
                                editableLocalization
                                onChange={(newDetails) => {
                                    setFormDetails(newDetails);
                                }}
                            />

                            {categoryDetailsStore.canEditIcon()
                                ? IconCard(icon)
                                : icon && <img src={icon} alt="icon" style={{ height: 'auto', width: '540px' }} />}
                            {categoryDetailsStore.canEditPhoto()
                                ? ImageCard(picture)
                                : picture && <img src={picture} alt="icon" style={{ height: 'auto', width: '540px' }} />}

                            <CoverageForm
                                coverage={coverage}
                                onChange={(newCoverage) => {
                                    setCoverage(newCoverage);
                                }}
                            />
                        </>
                    )}
                </Formik>
                <Paper style={{ padding: '20px' }}>
                    <h2>Categories/Subcategories:</h2>
                    {subCategories?.map((subCat) => (
                        <ShortCategoryCard category={subCat} onDelete={(id) => categoriesStore.delete(id)} />
                    ))}
                </Paper>
            </>
        );
    }

    return null;
});

export default CategoryDetailsPage;